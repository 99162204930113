// Functions to get Query Keys for react-query.
// Ref: https://react-query.tanstack.com/guides/query-keys

const getPatientByIdQueryKey = (patientId: string | undefined) => {
  return ['patient', patientId];
};

const getPatientShippingAddressQueryKey = (patientId: string) => {
  return ['patient', patientId, 'shipping-address'];
};

const getPatchFlowsByPatientQueryKey = (patientId: string) => {
  return ['patch-flows', patientId];
};

const getPatchFlowVisitAvailabilityQueryKey = (query: {
  [key: string]: Date | string | undefined,
}) => {
  return ['patch-flow-visit-availability', query]
}

const getPatchFlowVisitsByPatchFlowIdQueryKey = (patchFlowId: string | undefined) => {
  return ['patch-flow-vists', patchFlowId];
};

const getPatchOrdersByPatchFlowIdQueryKey = (patchFlowId: string | undefined) => {
  return ['patch-orders', patchFlowId];
};

const getPatchOrderByIdQueryKey = (patchOrderId: string | undefined) => {
  return ['patch-order', patchOrderId];
};

const getPatchResultsByPatchFlowIdQueryKey = (patchFlowId: string | undefined) => {
  return ['patch-results', patchFlowId];
};

const getPatchAllergenResultsByPatchResultIdQueryKey = (patchResultId: string | undefined) => {
  return ['patch-allergen-results', patchResultId];
};

const getQuestionnairesByPatchFlowIdQueryKey = (patchFlowId: string | undefined) => {
  return ['questionnaires', patchFlowId];
};

const getQuestionnaireByIdQueryKey = (questionnaireId: string | undefined) => {
  return ['questionnaire', questionnaireId];
};

const getQuestionnaireMedicationsByIdQueryKey = (questionnaireId: string | undefined) => {
  return ['questionnaire-medications', questionnaireId];
};

export {
  getPatchFlowsByPatientQueryKey,
  getPatchFlowVisitAvailabilityQueryKey,
  getPatchFlowVisitsByPatchFlowIdQueryKey,
  getPatchOrdersByPatchFlowIdQueryKey,
  getPatchOrderByIdQueryKey,
  getPatchResultsByPatchFlowIdQueryKey ,
  getPatchAllergenResultsByPatchResultIdQueryKey,
  getPatientByIdQueryKey,
  getPatientShippingAddressQueryKey,
  getQuestionnairesByPatchFlowIdQueryKey,
  getQuestionnaireByIdQueryKey,
  getQuestionnaireMedicationsByIdQueryKey,
};