import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';

import { useAuth } from 'webapp-common/auth/AuthProvider';
import { get, post } from 'webapp-common/api/RestApi';
import PatchFlow from 'webapp-common/models/PatchFlow';
import PatchFlowVisit from 'webapp-common/models/PatchFlowVisit';
import PatchFlowVisitType from 'webapp-common/models/PatchFlowVisitType';
import PatchOrder from 'webapp-common/models/PatchOrder';
import PatchResult from 'webapp-common/models/PatchResult';
import PatchAllergenResult from 'webapp-common/models/PatchAllergenResult';
import { getPatchAllergenName, getPatchAllergenWellNumber } from 'webapp-common/models/PatchAllergen';
import { getPatchAllergenResultScoreLabel } from 'webapp-common/models/PatchAllergenResultScore';
import Questionnaire from 'webapp-common/models/Questionnaire';
import QuestionnaireState, { getQuestionnaireState } from 'webapp-common/models/QuestionnaireState';

import PatchFlowStep from '../components/PatchFlowStep';
import PatchFlowStepState from '../types/PatchFlowStepState';
import Loading from '../components/Loading';
import {
  getPatchAllergenResultsByPatchResultIdQueryKey,
  getPatchFlowsByPatientQueryKey,
  getPatchFlowVisitsByPatchFlowIdQueryKey,
  getPatchOrdersByPatchFlowIdQueryKey,
  getPatchResultsByPatchFlowIdQueryKey,
  getQuestionnairesByPatchFlowIdQueryKey,
} from '../utils/query-keys';
import { getVisitTimeDisplayString } from '../utils/patch-flow-visit';


const isQuestionnaireStepCompleted = (questionnaireState: QuestionnaireState): boolean => {
  if (questionnaireState === QuestionnaireState.Approved || questionnaireState === QuestionnaireState.Denied) {
    return true;
  } else {
    return false;
  }
}

function QuestionnaireLink({
  questionnaireId,
  questionnaireState,
}: {
  questionnaireId: string,
  questionnaireState: QuestionnaireState,
}) {
  const link = `/questionnaires/${questionnaireId}`;
  switch (questionnaireState) {
    case QuestionnaireState.InProgress:
      return (
        <div>
          <p>Complete and submit your questionnaire <Link to={ link }>here</Link>.</p>
          <p>Once your questionnaire is submitted, it will be reviewed by one of our providers to determine eligibility.</p>
        </div>
      );
    case QuestionnaireState.Submitted:
      return (
        <div>
          <p>Your questionnaire is currently under review. You should receive the result within 48 hours of submitting the Questionnaire.</p>
          <p>View your questionnaire <Link to={ link }>here</Link>.</p>
        </div>
      );
    case QuestionnaireState.Approved:
      return (
        <div>
          <p>Your questionnaire was reviewed and you have been approved for the patch tests.</p>
          <p>View your questionnaire <Link to={ link }>here</Link>.</p>
        </div>
      );
    case QuestionnaireState.Denied:
      return (
        <div>
          <p>Your questionnaire was reviewed and unfortunately you are not a candidate for the patch test.</p>
          <p>For more information, view your questionnaire <Link to={ link }>here</Link>.</p>
        </div>
      );
    default:
      // Unreachable...
      return (<div></div>);
  }
}

enum PatchOrderState {
  QuestionnairePending,
  QuestionnaireDenied,
  QuestionnaireApproved,
  Ordered,
  Activated,
}

const getPatchOrderState = (questionnaireState: QuestionnaireState, patchOrder: PatchOrder | null): PatchOrderState => {
  if (!patchOrder) {
    switch (questionnaireState) {
      case QuestionnaireState.Approved:
        return PatchOrderState.QuestionnaireApproved;
      case QuestionnaireState.Denied:
        return PatchOrderState.QuestionnaireDenied;
      default:
        return PatchOrderState.QuestionnairePending;
    }
  }
  if (patchOrder.activation_code) {
    return PatchOrderState.Activated;
  } else {
    return PatchOrderState.Ordered;
  }
}

const getPatchOrderStepState = (patchOrderState: PatchOrderState): PatchFlowStepState => {
  switch (patchOrderState) {
    case PatchOrderState.Activated:
      return PatchFlowStepState.Completed;
    case PatchOrderState.QuestionnairePending:
      return PatchFlowStepState.Future;
    case PatchOrderState.QuestionnaireDenied:
      return PatchFlowStepState.Future;
    default:
      return PatchFlowStepState.InProgress;
  }
}

function OrderPatchBody({
  patchFlowId,
  patchOrderId,
  patchOrderState,
}: {
  patchFlowId: string | null,
  patchOrderId: string | null,
  patchOrderState: PatchOrderState,
}) {
  switch (patchOrderState) {
    case PatchOrderState.QuestionnairePending:
      return (
        <div>
          <p>You will be able to order your patch once your questionnaire is approved.</p>
        </div>
      );
    case PatchOrderState.QuestionnaireDenied:
      return (
        <div>
          <p>Unfotunately you cannot order a patch because your questionnaire was not approved.</p>
        </div>
      );
    case PatchOrderState.QuestionnaireApproved:
      const orderLink = `/patch-flows/${patchFlowId}/patch-order`;
      return (
        <div>
          <p>Order your patch <Link to={ orderLink }>here</Link>.</p>
        </div>
      );
    case PatchOrderState.Ordered:
      const activateLink = `/patch-orders/${patchOrderId}`;
      return (
        <div>
          <p>When you have received your patch, activate it <Link to={ activateLink }>here</Link>.</p>
        </div>
      );
    case PatchOrderState.Activated:
      return (
        <div>
          <p>Thank you for activating your patch.</p>
        </div>
      );
    default:
      // Unreachable...
      return (<div></div>);
  }
}

const getScheduleStepState = (patchOrderStepState: PatchFlowStepState, patchFlowVisits: PatchFlowVisit[] | null): PatchFlowStepState => {
  if (patchOrderStepState !== PatchFlowStepState.Completed) {
    return PatchFlowStepState.Future;
  }
  if (patchFlowVisits && patchFlowVisits.length === 3) {
    return PatchFlowStepState.Completed;
  }
  return PatchFlowStepState.InProgress;
};

const getVisitByType = (
  patchFlowVisits: PatchFlowVisit[] | null,
  visitType: PatchFlowVisitType,
) => {
  if (!patchFlowVisits) {
    return null;
  }
  const visit = patchFlowVisits.find((v) => v.visit_type === visitType);
  return visit || null;
};

const getVisitStepState = (
  previousStepState: PatchFlowStepState,
  patchFlowVisits: PatchFlowVisit[] | null,
  visitType: PatchFlowVisitType,
): PatchFlowStepState => {
  if (previousStepState !== PatchFlowStepState.Completed) {
    return PatchFlowStepState.Future;
  }
  const visit = getVisitByType(patchFlowVisits, visitType);
  if (!visit) {
    return PatchFlowStepState.Future;
  }
  const nowTime = new Date().getTime();
  const endTime = moment(new Date(visit.start_time)).add(15, 'minutes').toDate().getTime();
  if (endTime < nowTime) {
    return PatchFlowStepState.Completed;
  } else {
    return PatchFlowStepState.InProgress;
  }
};

const createPatchFlow = async (patientId: string) => {
  return await post<PatchFlow>('/patch-flows', {patient_id: patientId});
};

const createQuestionnaire = async (patchFlowId: string) => {
  return await post<Questionnaire>('/questionnaires', {patch_flow_id: patchFlowId});
};

const useGetOrCreatePatchFlow = (patientId: string) => {
  const queryClient = useQueryClient();
  const queryKey = getPatchFlowsByPatientQueryKey(patientId);

  // Get patch flow!
  const {
    data: patchFlows,
    isLoading: isLoadingPatchFlows,
  } = useQuery(queryKey, async () => {
    const patchFlows =  await get<PatchFlow[]>('/patch-flows', {patient_id: patientId});
    if (patchFlows.length > 1) {
      console.log(`Yikes! More than 1 patch flow: ${patchFlows.length}`);
    }
    return patchFlows;
  });
  const createPatchFlowMutation = useMutation(createPatchFlow, {
    onSuccess(patchFlow) {
      queryClient.setQueryData(queryKey, [patchFlow]);
    },
  });
  useEffect(() => {
    // If we fetched the patch flows but don't have one yet, then create it!
    if (!isLoadingPatchFlows && patchFlows && !patchFlows.length) {
      createPatchFlowMutation.mutate(patientId);
    }
  }, [patchFlows, isLoadingPatchFlows]);

  const patchFlow = (patchFlows && patchFlows.length && patchFlows[0]) || null;
  const isLoading = !patchFlow;

  return {
    isLoading,
    data: patchFlow || undefined,
  };
};

const useGetOrCreateQuestionnaire = (patchFlowId: string | undefined) => {
  const queryClient = useQueryClient();

  const {
    data: questionnaires,
    isLoading: isLoadingQuestionnaires,
  } = useQuery(getQuestionnairesByPatchFlowIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    const questionnaires = await get<Questionnaire[]>('/questionnaires', {patch_flow_id: patchFlowId});
    if (questionnaires.length > 1) {
      console.log(`Yikes! More than 1 questionnaire: ${questionnaires.length}`);
    }
    return questionnaires;
  }, {
    enabled: Boolean(patchFlowId),
  });

  const createQuestionnaireMutation = useMutation(createQuestionnaire, {
    onSuccess(questionnaire, patchFlowId) {
      queryClient.setQueryData(getQuestionnairesByPatchFlowIdQueryKey(patchFlowId), [questionnaire]);
    },
  });
  useEffect(() => {
    // If we fetched the questionnaires but don't have one yet, then create it!
    if (patchFlowId && !isLoadingQuestionnaires && questionnaires && !questionnaires.length) {
      createQuestionnaireMutation.mutate(patchFlowId);
    }
  }, [patchFlowId, questionnaires, isLoadingQuestionnaires]);

  const questionnaire = (questionnaires && questionnaires.length && questionnaires[0]) || null;
  const isLoading = !questionnaire;

  return {
    isLoading,
    data: questionnaire || undefined,
  };
};

const useGetPatchOrder = (patchFlowId: string | undefined) => {
  const {
    data: patchOrders,
    isLoading,
  } = useQuery(getPatchOrdersByPatchFlowIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    const patchOrders = await get<PatchOrder[]>('/patch-orders', {patch_flow_id: patchFlowId});
    if (patchOrders.length > 1) {
      console.log(`Yikes! More than 1 patch order: ${patchOrders.length}`);
    }
    return patchOrders;
  }, {
    enabled: Boolean(patchFlowId),
  });

  const patchOrder = (patchOrders && patchOrders.length && patchOrders[0]) || null;

  return {
    isLoading,
    data: patchOrder || undefined,
  };
};

const useGetPatchFlowVisits = (patchFlowId: string | undefined) => {
  const {
    data: patchFlowVisits,
  } = useQuery(getPatchFlowVisitsByPatchFlowIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    return get<PatchFlowVisit[]>(`/patch-flow-visits`, {patch_flow_id: patchFlowId});
  }, {
    enabled: Boolean(patchFlowId),
  });

  const isLoading = !patchFlowVisits;

  return {
    isLoading,
    data: patchFlowVisits || undefined,
  };
};

const useGetPatchResult = (patchFlowId: string | undefined, shouldFetch: boolean) => {
  const {
    data: patchResults,
  } = useQuery(getPatchResultsByPatchFlowIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    return await get<PatchResult[]>('/patch-results', {patch_flow_id: patchFlowId});
  }, {
    enabled: Boolean(patchFlowId) && shouldFetch,
  });

  const patchResult = (patchResults && patchResults.length && patchResults[0]) || null;
  const isLoading = !patchResults;

  return {
    isLoading,
    data: patchResult || undefined,
  }
}

const useGetPatchAllergenResults = (patchResultId: string | undefined) => {
  return useQuery(getPatchAllergenResultsByPatchResultIdQueryKey(patchResultId), async () => {
    if (!patchResultId) {
      throw new Error('Missing patch result ID');
    }
    return await get<PatchAllergenResult[]>('/patch-allergen-results', {patch_result_id: patchResultId});
  }, {
    enabled: Boolean(patchResultId),
  });
};

interface HomeData {
  patchFlow: PatchFlow,
  questionnaire: Questionnaire,
  patchOrder: PatchOrder | null,
  patchFlowVisits: PatchFlowVisit[] | null,
  patchResult: PatchResult | null,
  patchAllergenResults: PatchAllergenResult[] | null,
}

const useGetHomeData = (patientId: string): {
  isLoading: boolean,
  data: HomeData | undefined,
} => {
  const {
    data: patchFlow,
  } = useGetOrCreatePatchFlow(patientId);
  const patchFlowId = (patchFlow && patchFlow.id) || undefined;
  const {
    data: questionnaire,
  } = useGetOrCreateQuestionnaire(patchFlowId);
  const {
    data: patchOrder,
    isLoading: isLoadingPatchOrder,
  } = useGetPatchOrder(patchFlowId);
  const {
    data: patchFlowVisits,
    isLoading: isLoadingPatchFlowVisits,
  } = useGetPatchFlowVisits(patchFlowId);

  const now = new Date();
  const shouldFetchPatchResult = patchFlowVisits ? patchFlowVisits.every((v) => {
    const endTime = moment(v.start_time).add(10, 'minutes').toDate();
    return endTime < now;
  }) : false;

  const {
    data: patchResult,
    isLoading: isLoadingPatchResult,
  } = useGetPatchResult(patchFlowId, shouldFetchPatchResult);

  const patchResultId = (patchResult && patchResult.id) || undefined;
  const {
    data: patchAllergenResults,
    isLoading: isLoadingPatchAllergenResults,
  } = useGetPatchAllergenResults(patchResultId);

  const isLoading = (
    !patchFlow ||
    !questionnaire ||
    isLoadingPatchOrder ||
    isLoadingPatchFlowVisits ||
    (shouldFetchPatchResult && isLoadingPatchResult) ||
    (shouldFetchPatchResult && isLoadingPatchAllergenResults)
  );
  if (isLoading) {
    return {
      isLoading,
      data: undefined,
    };
  }
  return {
    isLoading,
    data: {
      patchFlow,
      questionnaire,
      patchOrder: patchOrder || null,
      patchFlowVisits: patchFlowVisits || null,
      patchResult: patchResult || null,
      patchAllergenResults: patchAllergenResults || null,
    },
  };
};

const getPatchFlowVisitTitle = (visitType: PatchFlowVisitType) => {
  switch (visitType) {
    case 'placement':
      return 'Patch Placement';
    case 'reading-one':
      return 'Reading One';
    case 'reading-two':
      return 'Reading Two';
    default:
      // Should not happen
      return '';
  }
}

function ScheduleAppointmentBody({
  patchFlowId,
  scheduleState,
  patchFlowVisits,
}: {
  patchFlowId: string | null,
  scheduleState: PatchFlowStepState,
  patchFlowVisits: PatchFlowVisit[] | null,
}) {
  const schedulingLink = `/patch-flows/${patchFlowId}/scheduling`;
  if (patchFlowVisits && patchFlowVisits.length) {
    // The `patchFlowVisits` should already be sorted ascending by `start_time`, but just in case
    // we sort them here...
    const visits = patchFlowVisits.map((v) => v);
    visits.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
    const elements = visits.map((visit) => {
      const title = getPatchFlowVisitTitle(visit.visit_type);
      const displayString = getVisitTimeDisplayString(new Date(visit.start_time));
      return <li key={visit.id}>{ title }: { displayString }</li>;
    });
    // Can not reschedule < 1 hour in advance
    // TODO: How much buffer should we allow?
    const oneHourAheadTime = moment().add(1, 'hour').toDate().getTime();
    const canReschedule = visits.length > 0 && new Date(visits[0].start_time).getTime() > oneHourAheadTime;
    return (
      <div>
        <p>Your appointments times are:</p>
        <ul>
          { elements }
        </ul>
        {canReschedule &&
          <p>To reschedule, go <Link to={ schedulingLink }>here</Link>.</p>
        }
      </div>
    );
  } else {
    if (scheduleState === PatchFlowStepState.Future) {
      return (
        <div>
          <p>When you have received your patch, you will be able to activate it and then schedule your appointments here.</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>You have activated your patch.</p>
          <p>You may now schedule your appointments <Link to={ schedulingLink }>here</Link>.</p>
        </div>
      );
    }
  }
};

function PatchFlowVisitBody({
  visitName,
  visit,
}: {
  visitName: string,
  visit: PatchFlowVisit | null,
}) {
  if (!visit) {
    return (
      <div>
        <p>You have not yet scheduled your appointment for { visitName }.</p>
      </div>
    );
  }
  const startTimeMoment = moment(new Date(visit.start_time));
  const endTimeMoment = moment(startTimeMoment).add(10, 'minutes');

  const nowTime = new Date().getTime();
  const endTime = endTimeMoment.toDate().getTime();
  // TODO: Presumably we will have some additional info regarding whether a visit
  // was actually completed other than the end time has passed...
  const isComplete = nowTime > endTime;

  const dayString = startTimeMoment.format('dddd, MMMM D, YYYY');
  const startTimeString = startTimeMoment.format('h:mma');
  const endTimeString = endTimeMoment.format('h:mma');

  const timeBlock = (
    <p>
      { dayString }
      <br/>
      { startTimeString } - { endTimeString }
    </p>
  );

  if (isComplete) {
    return (
      <div>
        <p>{ visitName } occurred on:</p>
        { timeBlock }
      </div>
    );
  }

  return (
    <div>
      <p>{ visitName } is scheduled for:</p>
      { timeBlock }
      <p>
        {/* TODO: Make `mobile app` a link. Ideally a deep link. */}
        Please join the video visit via the mobile app.
      </p>
    </div>
  );
};


function PatchResultView({
  patchResult,
  patchAllergenResults,
}: {
  patchResult: PatchResult,
  patchAllergenResults: PatchAllergenResult[],
}) {
  // No need to include the negative control!
  const results = patchAllergenResults.filter((res) => res.allergen !== 'negative-control');
  results.sort((a, b) => {
    return getPatchAllergenWellNumber(a.allergen) - getPatchAllergenWellNumber(b.allergen);
  });

  const submittedAtDayString = moment(patchResult.submitted_at).format('MMMM DD, YYYY');

  return (
    <Row className="justify-content-center mt-5 mb-5">
      <Col lg="8">
        <Table hover>
          <thead>
            <tr>
              <th></th>
              <th>Allergen</th>
              <th>Result</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {results.map((res) => {
              const bgClassName = (() => {
                switch (res.score) {
                  case 'negative':
                    return 'bg-success';
                  case 'irritant':
                    return 'bg-warning';
                  case 'positive':
                    return 'bg-danger';
                }
              })();
              return (
                <tr key={res.id} className={ `${bgClassName!} bg-opacity-25` }>
                  <td>{ getPatchAllergenWellNumber(res.allergen) }</td>
                  <td>{ getPatchAllergenName(res.allergen) }</td>
                  <td>{ res.score ? getPatchAllergenResultScoreLabel(res.score) : '' }</td>
                  <td>{res.notes || ''}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <p className="text-center">Results submitted on { submittedAtDayString }</p>
      </Col>
    </Row>
  );
}

function HomeRoute() {
  const auth = useAuth();

  // Override `string | undefined` because we know at this point
  // that the user must be authenticated and therefore `patientId`
  // is not `undefined`.
  const patientId = auth.user?.id as string;

  const {
    data: homeData,
    isLoading: isLoadingHomeData,
  } = useGetHomeData(patientId);

  if (isLoadingHomeData || !homeData) {
    return <Loading />;
  }

  const {
    patchFlow,
    questionnaire,
    patchOrder,
    patchFlowVisits,
    patchResult,
    patchAllergenResults,
  } = homeData;

  if (!questionnaire) {
    return <Loading />;
  }

  // We check for `submitted_at`, but really we don't even get it back from the server
  // if it hasn't been submitted.
  if (patchResult && patchResult.submitted_at && patchAllergenResults) {
    return (
      <PatchResultView
        patchResult={patchResult}
        patchAllergenResults={patchAllergenResults}
      />
    );
  }

  const patchFlowId = patchFlow.id;
  const patchOrderId = patchOrder?.id || null;
  const questionnaireState = getQuestionnaireState(questionnaire);
  const patchOrderState = getPatchOrderState(questionnaireState, patchOrder);
  const patchOrderStepState = getPatchOrderStepState(patchOrderState);
  const scheduleState = getScheduleStepState(patchOrderStepState, patchFlowVisits);
  const patchPlacementStepState = getVisitStepState(scheduleState, patchFlowVisits, 'placement');
  const readingOneStepState = getVisitStepState(patchPlacementStepState, patchFlowVisits, 'reading-one');
  const readingTwoStepState = getVisitStepState(readingOneStepState, patchFlowVisits, 'reading-two');

  const patchPlacementVisit = getVisitByType(patchFlowVisits, 'placement');
  const readingOneVisit = getVisitByType(patchFlowVisits, 'reading-one');
  const readingTwoVisit = getVisitByType(patchFlowVisits, 'reading-two');

  const steps = [{
    header: "Questionnaire",
    state: isQuestionnaireStepCompleted(questionnaireState) ? PatchFlowStepState.Completed : PatchFlowStepState.InProgress,
    content: (
      <QuestionnaireLink
        questionnaireId={ questionnaire.id }
        questionnaireState={ questionnaireState }
        />
    ),
  }, {
    header: "Order Patch",
    state: patchOrderStepState,
    content: (
      <OrderPatchBody
        patchFlowId={ patchFlowId }
        patchOrderId={ patchOrderId }
        patchOrderState={ patchOrderState }
      />
    ),
  }, {
    header: "Schedule Appointments",
    state: scheduleState,
    content: (
      <ScheduleAppointmentBody 
        patchFlowId={ patchFlowId }
        scheduleState={ scheduleState }
        patchFlowVisits={ patchFlowVisits }
      />
    ),
  }, {
    header: "Patch Placement",
    state: patchPlacementStepState,
    content: (
      <PatchFlowVisitBody
        visitName="Patch Placement"
        visit={patchPlacementVisit}
      />
    ),
  }, {
    header: "Reading #1",
    state: readingOneStepState,
    content: (
      <PatchFlowVisitBody
        visitName="Reading #1"
        visit={readingOneVisit}
      />
    ),
  }, {
    header: "Reading #2",
    state: readingTwoStepState,
    content: (
      <PatchFlowVisitBody
        visitName="Reading #2"
        visit={readingTwoVisit}
      />
    ),
  }];

  const patchFlowSteps = steps.map(({header, state, content}, idx) => {
    return (
      <PatchFlowStep
        key={idx.toString()}
        eventKey={idx.toString()}
        header={header}
        state={state}
        content={content}
      />
    )
  });
  const defaultActiveKeys = steps
    .map(({state}, idx) => ({state, key: idx.toString()}))
    .filter(({state}) => state === PatchFlowStepState.InProgress)
    .map(({key}) => key);

  const isAllCompleted = steps.every((s) => s.state === PatchFlowStepState.Completed);

  return (
    <div>
      <Row className="justify-content-center mt-5 mb-5">
        <Col sm="8" lg="6">
          <Accordion defaultActiveKey={defaultActiveKeys}>
            {patchFlowSteps}
          </Accordion>
          {isAllCompleted && (
            <p className="text-center mt-3">
              Your provider is finalizing your results.
              <br/>
              You should have them within 48 hours of completing Reading #2.
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default HomeRoute;