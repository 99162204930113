import { FormEventHandler, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

interface RegistrationConsentContainerProps {
  title: string,
  children: JSX.Element,
  canAgree: boolean,
  isSubmitting: boolean,
  submit: () => void,
}

function RegistrationConsentContainer({
  title,
  children,
  canAgree,
  submit,
  isSubmitting,
}: RegistrationConsentContainerProps) {
  const [isAgree, setIsAgree] = useState(false);

  const submitAgreement: FormEventHandler = async (event) => {
    event.preventDefault();
    if (!isAgree) {
      // That shouldn't happen...
      return;
    }
    submit();
  }

  return (
    <Row className="justify-content-center mt-5 mb-5">
      <Col sm="10" lg="8">
        <h2>{ title }</h2>
        <Card className="mb-3">
          <Card.Body>
            { children }
          </Card.Body>
        </Card>
        <Form noValidate onSubmit={submitAgreement}>
          <Form.Check type="checkbox" className="mb-3">
            <Form.Check.Input id="consent" type="checkbox" disabled={!canAgree} onChange={(e) => setIsAgree(e.target.checked)}/>
            <Form.Check.Label>I agree.</Form.Check.Label>
          </Form.Check>
          {isSubmitting ?
            <Button type="submit" disabled>Submitting...</Button>
            :
            <Button type="submit" disabled={!isAgree}>Submit</Button>
          }
        </Form>
      </Col>
    </Row>
  );
}

export default RegistrationConsentContainer;