import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import RegistrationConsentContainer from '../../components/registration/RegistrationConsentContainer';
import TreatmentConsentBody from '../../components/registration/TreatmentConsentBody';
import useUpdateConsentMutation from '../../utils/useUpdateConsentMutation';


function TreatmentConsentRoute() {
  const auth = useAuth();
  const updateConsentMutation = useUpdateConsentMutation();

  const [canAgree, setCanAgree] = useState(false);

  const submit = () => {
    const patientId = auth.user?.id;
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    updateConsentMutation.mutate({patientId, consentPath: 'treatment-consent'});
  }

  if (updateConsentMutation.isSuccess) {
    return <Navigate to="/registration" />;
  }

  return (
    <RegistrationConsentContainer
      title={ 'Consent to Treatment' }
      canAgree={ canAgree }
      isSubmitting={ updateConsentMutation.isLoading }
      submit={ submit }>
      <TreatmentConsentBody setAreAllChecked={ setCanAgree }/>
    </RegistrationConsentContainer>
  );
}

export default TreatmentConsentRoute;