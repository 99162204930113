// See: https://github.com/dropbox/zxcvbn#usage

import loadScript from "./load-script";

type ZxcvbnResult = {
  // estimated guesses needed to crack password
  guesses: number
  // order of magnitude of result.guesses
  guesses_log10: number,

  // dictionary of back-of-the-envelope crack time
  // estimations, in seconds, based on a few scenarios:
  crack_times_seconds: {
    // online attack on a service that ratelimits password auth attempts.
    online_throttling_100_per_hour: number,

    // online attack on a service that doesn't ratelimit,
    // or where an attacker has outsmarted ratelimiting.
    online_no_throttling_10_per_second: number,

    // offline attack. assumes multiple attackers,
    // proper user-unique salting, and a slow hash function
    // w/ moderate work factor, such as bcrypt, scrypt, PBKDF2.
    offline_slow_hashing_1e4_per_second: number,

    // offline attack with user-unique salting but a fast hash
    // function like SHA-1, SHA-256 or MD5. A wide range of
    // reasonable numbers anywhere from one billion - one trillion
    // guesses per second, depending on number of cores and machines.
    // ballparking at 10B/sec.
    offline_fast_hashing_1e10_per_second: number,
  },

  // same keys as result.crack_times_seconds,
  // with friendlier display string values:
  // "less than a second", "3 hours", "centuries", etc.
  crack_times_display: string ,
  
  // Integer from 0-4 (useful for implementing a strength bar)
  // 0 - too guessable: risky password. (guesses < 10^3)
  // 1 - very guessable: protection from throttled online attacks. (guesses < 10^6)
  // 2 - somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
  // 3 - safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
  // 4 - very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
  score: number,      

  // verbal feedback to help choose better passwords. set when score <= 2.
  feedback?: {

    // explains what's wrong, eg. 'this is a top-10 common password'.
    // not always set -- sometimes an empty string
    warning: string,

    // a possibly-empty list of suggestions to help choose a less
    // guessable password. eg. 'Add another word or two'
    suggestions: string[], 
  },

  // the list of patterns that zxcvbn based the
  // guess calculation on.
  sequence: string[],

  // how long it took zxcvbn to calculate an answer,
  // in milliseconds.
  calc_time: number, 
}

type ZxcvbnFn = (password: string, userInputs?: string[]) => ZxcvbnResult;
type AsyncZxcvbnFn = (password: string, userInputs?: string[]) => Promise<ZxcvbnResult>;


// Load `zxcvbn`.
const loadZxcvbn = async (): Promise<ZxcvbnFn> => {
  await loadScript('zxcvbn', '/js/zxcvbn.js');
  return (window as any).zxcvbn as ZxcvbnFn;
}

const zxcvbn: AsyncZxcvbnFn = async (password: string, userInputs?: string[]): Promise<ZxcvbnResult> => {
  const zxcvbnFn = await loadZxcvbn();
  return zxcvbnFn(password);
}

export default zxcvbn;

export {loadZxcvbn};