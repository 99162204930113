export default interface Patient {
  id: string,
  email: string,
  first_name: string,
  last_name: string,
  email_verified_at: Date | null,
  // TODO: We ought to make this `NaiveDate`
  birthdate: string | null,
  // TODO: We ought to make a `PhoneNumber` type...
  phone_number: string | null,
  shipping_address_id: string | null,
  hipaa_consent_at: Date | null,
  treatment_consent_at: Date | null,
  agree_technical_requirements_at: Date | null,
  agree_logistical_requirements_at: Date | null,
  agree_medical_requirements_at: Date | null,
}


const hasPatientFilledOutAdditionalInformation = (p: Patient): boolean => {
  // TODO: Add in:
  // - Phone Number
  // - Shipping Address
  return Boolean(p.birthdate && p.phone_number);
}


const isPatientFullyRegistered = (p: Patient): boolean => {
  return (
    hasPatientFilledOutAdditionalInformation(p) &&
    Boolean(
      p.hipaa_consent_at &&
      p.treatment_consent_at &&
      p.agree_technical_requirements_at &&
      p.agree_logistical_requirements_at &&
      p.agree_medical_requirements_at
    )
  );
};

export {
  hasPatientFilledOutAdditionalInformation,
  isPatientFullyRegistered,
};