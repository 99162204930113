import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useParams } from 'react-router-dom';
import { post } from 'webapp-common/api/RestApi';
import PatchOrder from 'webapp-common/models/PatchOrder';

function PatchFlowCreatePatchOrderRoute() {
  const { patchFlowId } = useParams();
  const [isOrdering, setIsOrdering] = useState(false);
  const [patchOrderId, setPatchOrderId] = useState<string | null>(null);
  
  // TODO: Fetch from DB in case one already exists!
  const orderPatch = async () => {
    if (!patchFlowId) {
      return;
    }
    setIsOrdering(true);
    const patchOrder = await post<PatchOrder>('/patch-orders', {patch_flow_id: patchFlowId});
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsOrdering(false);
    setPatchOrderId(patchOrder.id);
  };

  const orderOrConfirm = patchOrderId === null ? (
    <Button disabled={ isOrdering } variant="primary" onClick={ orderPatch }>
      { isOrdering ? 'Ordering...' : 'Order'}
    </Button>
  ) : (
    <div>
      <p>
        Thank you for your order!
      </p>
      <p>
        When you receive the patch you will be able to activate it.
      </p>
      <Link to="/" className="btn btn-outline-secondary btn-lg">Done</Link>
    </div>
  );

  return (
    <Row className="justify-content-center mt-5 mb-5">
      <Col sm="10" lg="8">
        <Alert variant="danger">
          TODO: Update!
          <ul>
            <li>
              Shipping address: either
              <ol type="a">
                <li>allow the patient to specify, or</li>
                <li>show them the shipping address they initially provided</li>
              </ol>
            </li>
            <li>
              Payment: either
              <ol type="a">
                <li>allow the patient to input payment,or</li>
                <li>charge their payment method provided earlier (with confirmation / info)</li>
              </ol>
            </li>
          </ul>
        </Alert>
        <h2>Order your Patch</h2>
        <p>Your patch should arrive in X-Y days/weeks...</p>
        { orderOrConfirm }
      </Col>
    </Row>
  );

};

export default PatchFlowCreatePatchOrderRoute;