import Alert from 'react-bootstrap/Alert';

function LogisticalRequirementsBody() {
  return (
    <div>
      <Alert variant="danger">
        TODO: UPDATE COPY
      </Alert>
      <p>Please review and agree to the following additional requirements:</p>
      <ul>
        <li>First requirement.</li>
        <li>Next requirement.</li>
        <li>Another requirement.</li>
      </ul>
    </div>
  )
}

export default LogisticalRequirementsBody;