import Alert from 'react-bootstrap/Alert';

function HippaConsentBody() {
  return (
    <div>
      <Alert variant="danger">
        TODO: Update with language for HIPAA consent.
      </Alert>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi tristique senectus et netus et. Semper risus in hendrerit gravida rutrum quisque non. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Et odio pellentesque diam volutpat commodo sed egestas egestas. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Ornare quam viverra orci sagittis eu. Facilisis leo vel fringilla est ullamcorper eget nulla facilisi etiam. Imperdiet sed euismod nisi porta lorem mollis. Rhoncus mattis rhoncus urna neque viverra justo. Tincidunt id aliquet risus feugiat in ante metus dictum. Nullam non nisi est sit amet facilisis magna. Etiam non quam lacus suspendisse. Feugiat vivamus at augue eget arcu dictum varius.</p>
      <p>Amet est placerat in egestas. Rhoncus mattis rhoncus urna neque viverra. Tristique senectus et netus et malesuada fames ac turpis egestas. Velit laoreet id donec ultrices. Sit amet est placerat in egestas erat imperdiet sed euismod. Aliquet lectus proin nibh nisl. Fames ac turpis egestas maecenas pharetra. Amet mattis vulputate enim nulla aliquet porttitor lacus. Nunc sed velit dignissim sodales ut eu. Posuere urna nec tincidunt praesent. Duis convallis convallis tellus id interdum velit laoreet. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Quam viverra orci sagittis eu volutpat odio facilisis mauris sit. Eget nullam non nisi est sit amet. Vitae tempus quam pellentesque nec nam aliquam. Senectus et netus et malesuada fames ac turpis egestas integer.</p>
      <p>Tellus in hac habitasse platea dictumst vestibulum. Cras pulvinar mattis nunc sed blandit libero volutpat. At erat pellentesque adipiscing commodo elit at imperdiet dui accumsan. Libero justo laoreet sit amet cursus sit amet. Quam viverra orci sagittis eu volutpat odio facilisis. Sed libero enim sed faucibus turpis in eu mi bibendum. Ut aliquam purus sit amet luctus. Adipiscing elit duis tristique sollicitudin nibh. Semper viverra nam libero justo laoreet sit amet cursus sit. Suscipit tellus mauris a diam maecenas sed. Cursus risus at ultrices mi tempus imperdiet nulla. Feugiat in fermentum posuere urna nec. Aliquam etiam erat velit scelerisque in dictum non consectetur. Sit amet aliquam id diam maecenas. Augue neque gravida in fermentum. Ultricies leo integer malesuada nunc vel risus commodo viverra maecenas. Nunc pulvinar sapien et ligula ullamcorper malesuada proin libero.</p>
      <p>Fames ac turpis egestas sed. Euismod lacinia at quis risus sed vulputate odio ut. Morbi leo urna molestie at elementum eu. Urna condimentum mattis pellentesque id nibh tortor id aliquet. Et tortor at risus viverra adipiscing at in tellus. Fusce ut placerat orci nulla pellentesque dignissim enim. Donec et odio pellentesque diam volutpat commodo. Tincidunt lobortis feugiat vivamus at. Enim blandit volutpat maecenas volutpat. Orci phasellus egestas tellus rutrum tellus. Orci nulla pellentesque dignissim enim. Sit amet porttitor eget dolor morbi non arcu risus. Quam quisque id diam vel quam elementum. Euismod nisi porta lorem mollis aliquam ut porttitor leo. Vel pretium lectus quam id. In pellentesque massa placerat duis. Pellentesque diam volutpat commodo sed egestas. Sed egestas egestas fringilla phasellus. Auctor augue mauris augue neque gravida.</p>
      <p>Ipsum a arcu cursus vitae congue mauris. At erat pellentesque adipiscing commodo elit. Senectus et netus et malesuada fames ac turpis egestas. Mauris nunc congue nisi vitae suscipit tellus. Ipsum dolor sit amet consectetur adipiscing elit ut. Et malesuada fames ac turpis egestas sed tempus urna. Orci ac auctor augue mauris augue neque. Id nibh tortor id aliquet. Nibh sed pulvinar proin gravida hendrerit lectus. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Eu non diam phasellus vestibulum lorem sed risus ultricies tristique. Tellus molestie nunc non blandit massa enim nec dui. Ante in nibh mauris cursus mattis. Tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Libero justo laoreet sit amet cursus sit amet. Et odio pellentesque diam volutpat commodo sed egestas egestas.</p>
    </div>
  )
}

export default HippaConsentBody;