import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaCircleNotch } from 'react-icons/fa';

import Questionnaire, { canSubmitQuestionnaire } from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';

import QuestionnaireResponsesTable from './QuestionnaireResponsesTable';


function QuestionnaireReviewAndSubmit({
  questionnaire,
  medications,
  submitQuestionnaire,
}: {
  questionnaire: Questionnaire,
  medications: QuestionnaireMedication[],
  submitQuestionnaire: () => Promise<void>,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = async () => {
    setIsSubmitting(true);
    await submitQuestionnaire();
    setIsSubmitting(false);
  };

  const getSubmitButton = (): JSX.Element => {
    if (isSubmitting) {
      return <Button disabled variant="outline-secondary" size="lg"><FaCircleNotch className="spin" /></Button>;
    } else if (canSubmitQuestionnaire(questionnaire)) {
      return <Button onClick={onClickSubmit} variant="outline-secondary" size="lg">Submit</Button>;
    } else {
      return <Button disabled variant="outline-secondary" size="lg">Submit</Button>;
    }
  }

  const submitButton = getSubmitButton();

  return (
    <div>
      <div className="m-5">
        <p>Please review and submit your responses.</p>
        <QuestionnaireResponsesTable
          questionnaire={questionnaire}
          medications={medications}
          showEditLink={true}
          />
        <div className="position-relative" style={{height: '100px'}}>
          <div className="position-absolute top-50 start-50 translate-middle">
            {submitButton}
          </div>
        </div>
      </div>
    </div>
  );
};


export default QuestionnaireReviewAndSubmit;