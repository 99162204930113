const NAIVE_DATE_RE = /^\d{4}-\d{2}-\d{2}$/;

class NaiveDate {
  private year: number
  private month: number // 0-11 like Date
  private date: number

  private constructor(year: number, month: number, date: number) {
    this.year = year;
    this.month = month;
    this.date = date;
  }

  static fromString(ymdStr: string): NaiveDate {
    if (!NAIVE_DATE_RE.test(ymdStr)) {
      throw new Error('Invalid NaiveDate string');
    }
    const d = new Date(ymdStr);
    if (isNaN(d.getTime())) {
      // 'Invalid Date'
      throw new Error('Invalid NaiveDate string');
    }
    const dateStr = d.toISOString();
    if (dateStr.substring(0, 10) !== ymdStr) {
      // e.g. `2000-02-31`
      throw new Error('Invalid NaiveDate string');
    }
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth();
    const date = d.getUTCDate();
    return new NaiveDate(year, month, date);
  }

  static tryFromString(ymdStr: string): NaiveDate | null {
    let nd = null;
    try {
      nd = NaiveDate.fromString(ymdStr);
    } catch {
      nd = null;
    }
    return nd;
  }

  toString(): string {
    return new Date(this.year, this.month, this.date)
      .toISOString()
      .substring(0, 10);
  }
}

export default NaiveDate;