import { FormEventHandler, MouseEventHandler, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useMutation } from 'react-query';

import { ApiError, patch, post } from 'webapp-common/api/RestApi';
import { useAuth, AuthContextType } from 'webapp-common/auth/AuthProvider';

import { isValidUuid } from '../utils/uuid';

interface SuccessMessage {
  success: boolean,
}

interface AlertMessage {
  message: string,
  variant: 'success' | 'danger',
}

const submitVerificationCode = async ({
  auth,
  email,
  verificationCode,
}: {
  auth: AuthContextType,
  email: string,
  verificationCode: string,
}) => {
  const body = {
    email,
    is_verified: true,
  };
  await patch<SuccessMessage>(`/email-verification/${verificationCode}`, body);
  // If we got here, then no error was thrown, meaning the request was a success and
  // the email was verified... So let's let the `auth` update itself...
  // TODO: we could probably just either:
  // 1. Have `PUT /email-verification/:code` respond with user info? (weird... not REST-ful)
  // 2. Update `auth.user` synchronously:
  //    - Expose method on `auth` called `setIsEmailVerified` which turns around and calls
  //      `setUser` with the `isEmailVerified` field updated.
  await auth.load();
};

const resendVerificationCode = async (email: string) => {
  const body = {email};
  await post<SuccessMessage>("/email-verification", body);
}

function VerifyEmailRoute() {
  const location = useLocation();

  const [verificationCode, setVerificationCode] = useState('');
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);

  const verificationMutation = useMutation(submitVerificationCode);
  const resendMutation = useMutation(resendVerificationCode);

  // If user is already verified, then just send them to "/"
  const auth = useAuth();
  if (auth.user?.isEmailVerified) {
    return <Navigate to="/" />;
  }

  const setErrorMessage = (message: string) => {
    setAlertMessage({message, variant: 'danger'});
  }

  // For redirect after login
  const state = location.state as {from?: {pathname: string}};
  const from = state?.from?.pathname || "/";

  const submit: FormEventHandler = async (event) => {
    event.preventDefault();
    resendMutation.reset();
    setAlertMessage(null);
    if (!isValidUuid(verificationCode)) {
      setErrorMessage('Invalid verification code');
      return;
    }
    if (!auth.user) {
      setErrorMessage('Unknown error');
      return;
    }
    verificationMutation.mutate({
      auth,
      email: auth.user.email,
      verificationCode,
    });
  };

  const resendEmailVerificationCode: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    setAlertMessage(null);

    if (!auth.user) {
      setErrorMessage('Unknown error');
      return;
    }

    resendMutation.mutate(auth.user.email);
  }

  if (verificationMutation.isSuccess) {
    return <Navigate to={ from } />;
  }

  const isSubmitting = verificationMutation.isLoading;
  const isResending = resendMutation.isLoading;

  const getAlertMessage = () => {
    if (alertMessage) {
      return alertMessage;
    }
    if (resendMutation.isSuccess) {
      return {
        message: 'New verification email sent!',
        variant: 'success',
      };
    }
    if (verificationMutation.error) {
      return {
        message: (verificationMutation.error as ApiError).error,
        variant: 'danger',
      };
    }
    if (resendMutation.error) {
      return {
        message: (verificationMutation.error as ApiError).error,
        variant: 'danger',
      };
    }
    return null;
  };
  const finalAlertMessage = getAlertMessage();

  return (
    <div>
      <Row className="justify-content-center mt-5">
        <Col sm="6" lg="4">
          <p>Please verify your email address by entering the verification code sent to your email.</p>
          {finalAlertMessage && 
            <Alert variant={finalAlertMessage.variant}>
              {finalAlertMessage.message}
            </Alert>
          }
          <Form className="mb-3" onSubmit={submit}>
            <Form.Group className="mb-3" controlId="verificationCode">
              <Form.Control type="text" placeholder="Verification Code" onChange={(e) => setVerificationCode(e.target.value)}/>
            </Form.Group>
            <Button disabled={isSubmitting} type="submit" className="w-100">
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
          <div className="text-center">Can't find the email?</div>
          <div className="text-center">
            <Button className="p-0" variant="link" disabled={isResending} onClick={(e) => resendEmailVerificationCode(e)}>
              {isResending ? 'Resending...' : 'Resend it'}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )

}

export default VerifyEmailRoute;