import { useEffect } from 'react';
import { useLocation, useNavigationType, useSearchParams } from 'react-router-dom';

function ScrollToTop({ children }: { children: JSX.Element }) {
  const { pathname } = useLocation();
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [pathname, searchParams, navigationType]);

  return children;
}

export default ScrollToTop;