import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { del, get, patch, post } from 'webapp-common/api/RestApi';
import Questionnaire, { isQuestionnaireSubmitted } from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';

import QuestionnaireBooleanQuestion from '../../components/questionnaire/QuestionnaireBooleanQuestion';
import QuestionnaireMedicationsList from '../../components/questionnaire/QuestionnaireMedicationsList';
import QuestionnaireReviewAndSubmit from '../../components/questionnaire/QuestionnaireReviewAndSubmit';
import QuestionnaireSubmitted from '../../components/questionnaire/QuestionnaireSubmitted';
import QuestionnaireProgressBar from '../../components/questionnaire/QuestionnaireProgressBar';

import QuestionnaireCompletionState, {
  getLinkForQuestionnaireCompletionState,
  questionnaireCompletionStateFromQueryString,
} from '../../types/QuestionnaireCompletionState';
import QuestionnaireCompletionStep from '../../types/QuestionnaireCompletionStep';
import QuestionnaireInputMedication from '../../types/QuestionnaireInputMedication';
import QuestionnairePatchUpdate from '../../types/QuestionnairePatchUpdate';
import Loading from '../../components/Loading';
import { getQuestionnaireByIdQueryKey, getQuestionnaireMedicationsByIdQueryKey } from '../../utils/query-keys';


const getQuestionnaireCompletionSteps = ({
  questionnaire,
  medications,
  updateQuestionnaire,
  addMedication,
  removeMedication,
  submitQuestionnaire,
}: {
  questionnaire: Questionnaire | undefined,
  medications: QuestionnaireMedication[] | undefined,
  updateQuestionnaire: (update: QuestionnairePatchUpdate) => Promise<void>,
  addMedication: (medication: QuestionnaireInputMedication) => Promise<void>,
  removeMedication: (medicationId: string) => Promise<void>,
  submitQuestionnaire: () => Promise<void>,
}): QuestionnaireCompletionStep[] => {
  if (!questionnaire || !medications) {
    return [];
  }
  return [{
    state: QuestionnaireCompletionState.Pregnant,
    isComplete: questionnaire.is_pregnant_or_breastfeeding !== null,
    canContinue: questionnaire.is_pregnant_or_breastfeeding !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.is_pregnant_or_breastfeeding}
        setResponse={async (val) => await updateQuestionnaire({is_pregnant_or_breastfeeding: val})}
        >
        <p>Are you pregnant or breastfeeding?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.TopicalCream,
    isComplete: questionnaire.has_used_topical_creams !== null,
    canContinue: questionnaire.has_used_topical_creams !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.has_used_topical_creams}
        setResponse={async (val) => await updateQuestionnaire({has_used_topical_creams: val})}
        >
        <p>Have you put any steroid creams or other immunosuppressant topical creams [Elidel (pimecrolimus), Protopic (tacrolimus), Eucrisa (crisaborole)] on your upper arm skin during the last 7 days?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.Steroids,
    isComplete: questionnaire.has_used_steroids !== null,
    canContinue: questionnaire.has_used_steroids !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.has_used_steroids}
        setResponse={async (val) => await updateQuestionnaire({has_used_steroids: val})}
        >
        <p>Have you taken any oral or injectable treatment with steroids in the last 30 days? Have you taken any other medications that suppress your immune system in the last 30 days?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.UvTreatment,
    isComplete: questionnaire.has_done_uv_treatment !== null,
    canContinue: questionnaire.has_done_uv_treatment !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.has_done_uv_treatment}
        setResponse={async (val) => await updateQuestionnaire({has_done_uv_treatment: val})}
        >
        <p>Have you had any treatment with ultraviolet (UV) light (including tanning) during the previous 3 weeks?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.ArmRash,
    isComplete: questionnaire.has_active_arm_rash !== null,
    canContinue: questionnaire.has_active_arm_rash !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.has_active_arm_rash}
        setResponse={async (val) => await updateQuestionnaire({has_active_arm_rash: val})}
        >
        <p>Do you have any active rash on both of your upper arms (between your elbow and your shoulder) where the patch will be placed?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.ArmMarkings,
    isComplete: questionnaire.has_arm_markings !== null,
    canContinue: questionnaire.has_arm_markings !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.has_arm_markings}
        setResponse={async (val) => await updateQuestionnaire({has_arm_markings: val})}
        >
        <p>Do you have any tattoos or permanent markings on BOTH of your upper arms which may affect being able to read the patch?</p>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.Medications,
    isComplete: questionnaire.has_completed_medications === true,
    canContinue: questionnaire.has_completed_medications === true,
    // More in-depth than `QuestionnaireBooleanQuestion`
    component: (
      <QuestionnaireMedicationsList
        medications={medications}
        isDone={questionnaire.has_completed_medications === true}
        addMedication={addMedication}
        removeMedication={removeMedication}
        setIsDone={async (val) => await updateQuestionnaire({has_completed_medications: val})} />
    ),
  }, {
    state: QuestionnaireCompletionState.Schedule,
    isComplete: questionnaire.can_commit_to_schedule !== null,
    canContinue: questionnaire.can_commit_to_schedule !== null,
    component: (
      <QuestionnaireBooleanQuestion
        answer={questionnaire.can_commit_to_schedule}
        setResponse={async (val) => await updateQuestionnaire({can_commit_to_schedule: val})}
        >
        <p>
          The timing is strict in order to produce accurate test results.
          Once appointments are made, they cannot be rescheduled and the test is non-refundable.
          Are you able to commit to the 3 virtual video appointments [lasting 5 to 10 minutes] scheduled by you as follows?
        </p>
        <ul className="text-start">
          <li><strong>1st Visit:</strong> Patch placement (kept on for 48 hours without removal)</li>
          <li><strong>2nd Visit (48 hours later):</strong> Patch removal and reading #1</li>
          <li><strong>3rd Visit (48-72 hours later):</strong> Patch reading #2</li>
        </ul>
      </QuestionnaireBooleanQuestion>
    ),
  }, {
    state: QuestionnaireCompletionState.Review,
    isComplete: questionnaire.submitted_at !== null,
    canContinue: true,
    component: <QuestionnaireReviewAndSubmit questionnaire={questionnaire} medications={medications} submitQuestionnaire={submitQuestionnaire} />,
  }].map((elem, index) => {
    return {
      index,
      ...elem,
    };
  });
}

const fetchQuestionnaire = async (questionnaireId: string) => {
  return await get<Questionnaire>(`/questionnaires/${questionnaireId}`);
};
const fetchQuestionnaireMedications = async (questionnaireId: string) => {
  return await get<QuestionnaireMedication[]>(`/questionnaires/${questionnaireId}/medications`);
};
interface PatchQuestionnaireParams {
  questionnaireId: string,
  update: QuestionnairePatchUpdate,
}
const patchQuestionnaire = async ({questionnaireId, update}: PatchQuestionnaireParams) => {
  return await patch<Questionnaire>(`/questionnaires/${questionnaireId}`, update);
};
const postQuestionnaireSubmit = async (questionnaireId: string) => {
  return await post<Questionnaire>(`/questionnaires/${questionnaireId}/submit`);
};

interface PostMedicationParams {
  questionnaireId: string,
  medication: QuestionnaireInputMedication,
}
const postMedication = async ({questionnaireId, medication}: PostMedicationParams) => {
  return await post<QuestionnaireMedication>(`/questionnaires/${questionnaireId}/medications`, medication);
}
interface DeleteMedicationParams {
  questionnaireId: string,
  medicationId: string,
}
const deleteMedication = async ({questionnaireId, medicationId}: DeleteMedicationParams) => {
  return await del<{success: boolean}>(`/questionnaires/${questionnaireId}/medications/${medicationId}`);
};

function QuestionnaireRoute() {
  // 1. Actually get the questionnaire from react-query (local or server).
  // 2. If questionnaire is complete, then render that (whether or not it is reviewed could be in that component OR separate).
  // 3. If questionnaire is NOT complete, then look at query params to check which question to render.
  //    If no question is in the query params, then send to first unanswered question (or "review" page).

  const { questionnaireId } = useParams();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const questionnaireByIdQueryKey = getQuestionnaireByIdQueryKey(questionnaireId);
  const {
    data: questionnaire,
    isLoading: isLoadingQuestionnaire,
  } = useQuery(questionnaireByIdQueryKey, async () => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    return await fetchQuestionnaire(questionnaireId);
  });

  const questionnaireMedicationsQueryKey = getQuestionnaireMedicationsByIdQueryKey(questionnaireId);
  const {
    data: medications,
    isLoading: isLoadingMedications,
  } = useQuery(questionnaireMedicationsQueryKey, async () => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    return await fetchQuestionnaireMedications(questionnaireId);
  });

  const updateQuestionnaireMutation = useMutation(patchQuestionnaire, {
    onSuccess(questionnaire) {
      const queryKey = getQuestionnaireByIdQueryKey(questionnaire.id);
      queryClient.setQueryData(queryKey, questionnaire);
    },
  });

  const updateQuestionnaire = async (update: QuestionnairePatchUpdate) => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    await updateQuestionnaireMutation.mutateAsync({questionnaireId, update});
  };

  const addMedicationMutation = useMutation(postMedication, {
    onSuccess(medication, {questionnaireId}) {
      const queryKey = getQuestionnaireMedicationsByIdQueryKey(questionnaireId);
      queryClient.setQueryData(queryKey, (medications: QuestionnaireMedication[] | undefined) => {
        return [...(medications || []), medication];
      });
    },
  });

  const addMedication = async (inputMed: QuestionnaireInputMedication) => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    await addMedicationMutation.mutateAsync({questionnaireId, medication: inputMed});
  };

  const removeMedicationMutation = useMutation(deleteMedication, {
    onSuccess(_, {questionnaireId, medicationId}) {
      const queryKey = getQuestionnaireMedicationsByIdQueryKey(questionnaireId);
      queryClient.setQueryData(queryKey, (medications: QuestionnaireMedication[] | undefined) => {
        return (medications || []).filter((med) => med.id !== medicationId);
      });
    },
  });
  const removeMedication = async (medicationId: string) => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    await removeMedicationMutation.mutateAsync({questionnaireId, medicationId});
  };

  const submitQuestionnaireMutation = useMutation(postQuestionnaireSubmit, {
    onSuccess(questionnaire) {
      const queryKey = getQuestionnaireByIdQueryKey(questionnaire.id);
      queryClient.setQueryData(queryKey, questionnaire);
    },
  });
  const submitQuestionnaire = async () => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    await submitQuestionnaireMutation.mutateAsync(questionnaireId);
    setSearchParams({});
  }

  const stateFromSearchParam = questionnaireCompletionStateFromQueryString(searchParams.get("q"));

  const questionnaireCompletionSteps = getQuestionnaireCompletionSteps({
    questionnaire,
    medications,
    updateQuestionnaire,
    addMedication,
    removeMedication,
    submitQuestionnaire,
  });

  const getCurrentCompletionStep = () => {
    // can't happen...
    if (!questionnaire) return null;

    if (isQuestionnaireSubmitted(questionnaire)) {
      return null;
    }

    // Find based on query param.
    if (stateFromSearchParam) {
      const stepFromSearchParam = questionnaireCompletionSteps.find((s) => s.state === stateFromSearchParam);
      if (stepFromSearchParam) {
        return stepFromSearchParam;
      }
    }
    const nextStepToComplete = questionnaireCompletionSteps.find(({isComplete}) => !isComplete);
    // Go to next question or Review
    return nextStepToComplete || questionnaireCompletionSteps[questionnaireCompletionSteps.length - 1];
  };

  const currentCompletionStep = getCurrentCompletionStep();

  // Update the query parameter if it is incorrect, which could happen if:
  // 1. It was empty -- in which case we go to the first incomplete step.
  // 2. It didn't map to an existing state -- in which case we also go to the first incomplete step.
  // Note that `currentCompletionStep` is only `null` if the questionnaire has already been completed.
  useEffect(() => {
    if (questionnaire && currentCompletionStep && currentCompletionStep.state !== stateFromSearchParam) {
      setSearchParams({q: currentCompletionStep.state});
    }
  });

  if (isLoadingQuestionnaire || isLoadingMedications) {
    return <Loading />;
  }

  if (!questionnaire || !medications) {
    return <Loading />;
  }

  if (!currentCompletionStep) {
    return <QuestionnaireSubmitted questionnaire={questionnaire} medications={medications} />
  }

  const getPreviousState = (): QuestionnaireCompletionState => {
    if (currentCompletionStep.index === 0) {
      return currentCompletionStep.state;
    }
    const prevStateIndex = currentCompletionStep.index - 1;
    return questionnaireCompletionSteps[prevStateIndex].state;
  };
  const prevLink = getLinkForQuestionnaireCompletionState(questionnaire.id, getPreviousState());
  const getNextState = (): QuestionnaireCompletionState => {
    const nextState = questionnaireCompletionSteps.find(({index, isComplete}) => {
      return (index > currentCompletionStep.index) && !isComplete;
    });
    return nextState?.state || QuestionnaireCompletionState.Review;
  };
  const nextLink = getLinkForQuestionnaireCompletionState(questionnaire.id, getNextState());

  const shouldShowNextLink = currentCompletionStep.state !== QuestionnaireCompletionState.Review;
  const prevLinkClassName = `btn btn-outline-secondary btn-lg${shouldShowNextLink ? ' me-3' : ''}`
  const nextLinkClassName = `btn btn-outline-secondary btn-lg ms-3${currentCompletionStep.canContinue ? '' : ' disabled'}`;


  const progressSteps = questionnaireCompletionSteps.map(({isComplete, state}) => {
    return {
      isActive: currentCompletionStep.state === state,
      isComplete,
      link: getLinkForQuestionnaireCompletionState(questionnaire.id, state),
    };
  });

  return (
    <Row className="justify-content-center mt-5 mb-5">
      <Col sm="10" lg="8">
        <QuestionnaireProgressBar
          steps={progressSteps}
          className="position-relative m-4 pb-2" />
        <Card className="mb-3 shadow">
          <Card.Body>
            { currentCompletionStep.component }
          </Card.Body>
        </Card>
        <div className="position-relative mt-4" style={{height: '50px'}}>
          <div className="position-absolute top-50 start-50 translate-middle">
            {currentCompletionStep.index > 0 && <Link to={prevLink} className={prevLinkClassName}>Back</Link>}
            {shouldShowNextLink && 
              <Link to={nextLink} className={nextLinkClassName}>Continue</Link>
            }
          </div>
        </div>
      </Col>
    </Row>
  )
};

export default QuestionnaireRoute;