import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaCircleNotch } from 'react-icons/fa';


function QuestionnaireBooleanQuestion({
  children,
  answer,
  setResponse,
}: {
  children: JSX.Element | JSX.Element[],
  answer: boolean | null,
  setResponse: (response: boolean | null) => Promise<void>,
}) {
  const [isResponding, setIsResponding] = useState(false);

  const isNoActive = answer === false;
  const isYesActive = answer === true;

  const onClick = (val: boolean | null) => async () => {
    setIsResponding(true);
    await setResponse(val);
    setIsResponding(false);
  };

  const buttons = isResponding ? [
      <Button key="respond-no" disabled variant="outline-secondary" size="lg" className="me-5"><FaCircleNotch className="spin" /></Button>,
      <Button key="respond-yes" disabled variant="outline-secondary" size="lg" className="ms-5"><FaCircleNotch className="spin" /></Button>,
  ] : [
      <Button key="respond-no" active={isNoActive} onClick={onClick(false)} variant="outline-secondary" size="lg" className="me-5">No</Button>,
      <Button key="respond-yes" active={isYesActive} onClick={onClick(true)} variant="outline-secondary" size="lg" className="ms-5">Yes</Button>,
  ];

  return (
    <div>
      <div className="m-3">
        {children}
      </div>
      <div className="position-relative" style={{height: '100px'}}>
        <div className="position-absolute top-50 start-50 translate-middle">
          {buttons}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireBooleanQuestion;