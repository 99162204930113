import { Navigate } from 'react-router-dom';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import RegistrationConsentContainer from '../../components/registration/RegistrationConsentContainer';
import LogisticalRequirementsBody from '../../components/registration/LogisticalRequirementsBody';
import useUpdateConsentMutation from '../../utils/useUpdateConsentMutation';


function LogisticalRequirementsRoute() {
  const auth = useAuth();
  const updateConsentMutation = useUpdateConsentMutation();

  const submit = () => {
    const patientId = auth.user?.id;
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    updateConsentMutation.mutate({patientId, consentPath: 'logistical-requirements'});
  }

  if (updateConsentMutation.isSuccess) {
    return <Navigate to="/registration" />;
  }

  return (
    <RegistrationConsentContainer
      title={ 'Logistical Requirements' }
      canAgree={ true }
      isSubmitting={ updateConsentMutation.isLoading }
      submit={ submit }>
      <LogisticalRequirementsBody />
    </RegistrationConsentContainer>
  );
}

export default LogisticalRequirementsRoute;