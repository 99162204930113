import { Navigate } from 'react-router-dom';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import RegistrationConsentContainer from '../../components/registration/RegistrationConsentContainer';
import TechnicalRequirementsBody from '../../components/registration/TechnicalRequirementsBody';
import useUpdateConsentMutation from '../../utils/useUpdateConsentMutation';


function TechnicalRequirementsRoute() {
  const auth = useAuth();
  const updateConsentMutation = useUpdateConsentMutation();

  const submit = () => {
    const patientId = auth.user?.id;
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    updateConsentMutation.mutate({patientId, consentPath: 'technical-requirements'});
  }

  if (updateConsentMutation.isSuccess) {
    return <Navigate to="/registration" />;
  }

  return (
    <RegistrationConsentContainer
      title={ 'Technical Requirements' }
      canAgree={ true }
      isSubmitting={ updateConsentMutation.isLoading }
      submit={ submit }>
      <TechnicalRequirementsBody />
    </RegistrationConsentContainer>
  );
}

export default TechnicalRequirementsRoute;