import moment from 'moment';

const getVisitTimeDisplayString = (startTime: Date) => {
  const startDayStr = moment(startTime).format('ddd, MMM D, YYYY');
  const endTime = new Date(startTime);
  endTime.setMinutes(startTime.getMinutes() + 10);
  const startTimeStr = moment(startTime).format('h:mma');
  const endTimeStr = moment(endTime).format('h:mma');
  return `${startDayStr}, ${startTimeStr} - ${endTimeStr}`;
};

export {
  getVisitTimeDisplayString,
};