import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { FaTimes } from 'react-icons/fa';
import { FaCircleNotch } from 'react-icons/fa';

import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';

import QuestionnaireAddMedicationForm from './QuestionnaireAddMedicationForm';
import QuestionnaireInputMedication from '../../types/QuestionnaireInputMedication';

function QuestionnaireMedicationsList({
  medications,
  isDone,
  addMedication,
  removeMedication,
  setIsDone,
}: {
  medications: QuestionnaireMedication[],
  isDone: boolean,
  addMedication: (med: QuestionnaireInputMedication) => Promise<void>,
  removeMedication: (medId: string) => Promise<void>,
  setIsDone: (isDone: boolean) => Promise<void>,
}) {
  const [isCheckingBox, setIsCheckingBox] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const onAddMedication = async (med: QuestionnaireInputMedication): Promise<void> => {
    await addMedication(med);
    setShowModal(false);
  };

  const updateIsDone = async (isDone: boolean): Promise<void> => {
    setIsCheckingBox(true);
    await setIsDone(isDone);
    setIsCheckingBox(false);
  }
  
  return (
    <div>
      <div className="m-3">
        <p>
          Please list all of your medications including name, dosage, and frequency.
          Please also include any medications that were discontinued up to 30 days ago.
        </p>
        <Table>
          <thead>
            <tr>
              <th>Name of Medication</th>
              <th>Dose</th>
              <th>Frequency</th>
              <th>Why do you take this?</th>
              <th>Additional Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {medications.map((med, index) => (
              <tr key={index.toString()}>
                <td>{med.name}</td>
                <td>{med.dose}</td>
                <td>{med.frequency}</td>
                <td>{med.reason}</td>
                <td>{med.notes}</td>
                <td>
                  <Button onClick={() => removeMedication(med.id)} variant="light" size="sm"><FaTimes className="text-danger" /></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button onClick={handleShowModal} variant="outline-secondary" size="lg" className="mb-5">Add Medication</Button>
        <Form.Group>
          <Form.Check type="checkbox">
            <Form.Check.Input disabled={isCheckingBox} type="checkbox" checked={isDone} onChange={() => updateIsDone(!isDone)} />
            <Form.Check.Label>
              I have added all relevant medications.
              {isCheckingBox && 
                <span className="ms-1"><FaCircleNotch className="spin" /></span>
              }
            </Form.Check.Label>
            <div>
              <Form.Text>You may still add or remove medications after checking this box.</Form.Text>
            </div>
          </Form.Check>
        </Form.Group>

        <Modal show={showModal} onHide={handleHideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Medication</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QuestionnaireAddMedicationForm addMedication={onAddMedication} />
          </Modal.Body>
        </Modal>
      </div>

    </div>
  );
};

export default QuestionnaireMedicationsList;