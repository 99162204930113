type PatchAllergenResultScore =
  'negative' |
  'irritant' |
  'positive';

const PATCH_ALLERGEN_RESULT_SCORES: PatchAllergenResultScore[] = [
  'negative',
  'irritant',
  'positive',
];

const getPatchAllergenResultScoreLabel = (score: PatchAllergenResultScore): string => {
  switch (score) {
    case 'negative':
      return 'Negative';
    case 'irritant':
      return 'Irritant';
    case 'positive':
      return 'Positive';
  }
}

export default PatchAllergenResultScore;
export {
  PATCH_ALLERGEN_RESULT_SCORES,
  getPatchAllergenResultScoreLabel,
};