import { useMutation, useQueryClient } from 'react-query';

import { post } from 'webapp-common/api/RestApi';
import Patient from 'webapp-common/models/Patient';
import { getPatientByIdQueryKey } from './query-keys';


type PatientConsentPath = 'hipaa-consent' | 'treatment-consent' | 'technical-requirements' | 'logistical-requirements' | 'medical-requirements';
type UpdatePatientConsentArgs = {
  patientId: string,
  consentPath: PatientConsentPath,
}
const updateConsentForPatientById = async ({patientId, consentPath}: UpdatePatientConsentArgs) => {
  const path = `/patients/${patientId}/${consentPath}`;
  return await post<Patient>(path, {value: true});
};

export default () => {
  const queryClient = useQueryClient();

  return useMutation(updateConsentForPatientById, {
    onSuccess(patient, { patientId }) {
      const queryKey = getPatientByIdQueryKey(patientId);
      queryClient.setQueryData(queryKey, patient);
    },
  });
};