import { FormEventHandler, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { ApiError, post } from 'webapp-common/api/RestApi';
import { useAuth, AuthContextType } from 'webapp-common/auth/AuthProvider';

import { Link, Navigate, useLocation } from 'react-router-dom';
import zxcvbn, { loadZxcvbn } from '../utils/zxcvbn';
import PasswordStrengthMeter from '../components/PasswordStrengthMeter';
import { useMutation } from 'react-query';

type CreatePatientResult = {
  id: string,
};

const registerPatient = async ({
  auth,
  email,
  firstName,
  lastName,
  password,
}: {
  auth: AuthContextType,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
}) => {
  // TODO: Correct flow should be:
  // 1. Register (POST /patients)
  // 2. Upon success, send to `/verify` route.
  // 3. The `/verify` route prompts the user to input their code.
  // 4. Once verified, the rest of the app opens up!
  const body = {
    email,
    first_name: firstName,
    last_name: lastName,
    password,
  };
  const _result = await post<CreatePatientResult>('/patients', body);
  // now log in...
  await auth.signin(email, password, 'patient');
}

function RegisterRoute() {
  const location = useLocation();

  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(-1);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isTosAgree, setIsTosAgree] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [error, setError] = useState('');
  const [invalids, setInvalids] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    isTosAgree: false,
    isAdult: false,
  });

  // Start loading `zxcvbn` right away, since we'll probably need it!
  useEffect(() => {
    loadZxcvbn();
  }, []);

  const registerMutation = useMutation(registerPatient);

  // Don't let users go to `/register` if they are already signed in!
  if (auth.user) {
    return <Navigate to="/" replace />;
  }

  const getPasswordScore = async (value: string): Promise<number> => {
    if (value === '') {
      return -1;
    }
    const { score } = await zxcvbn(value);
    return score;
  }

  const onChangePassword = async (value: string) => {
    setPassword(value);
    const score = await getPasswordScore(value);
    setPasswordScore(score);
  };

  // For redirect after login
  const state = location.state as {from?: {pathname: string}};
  const from = state?.from?.pathname || "/";

  const clearFormValidationOutput = () => {
    setError('');
    setInvalids({
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      confirmPassword: false,
      isTosAgree: false,
      isAdult: false,
    });
  }

  const submitRegister: FormEventHandler = async (event) => {
    event.preventDefault();
    clearFormValidationOutput();

    const score = await getPasswordScore(password);
    const newInvalids = {
      firstName: !firstName,
      lastName: !lastName,
      email: !email,
      password: score <= 2,
      confirmPassword: password !== confirmPassword,
      isTosAgree: !isTosAgree,
      isAdult: !isAdult,
    };
    const isValid = !Object.values(newInvalids).includes(true);
    setInvalids(newInvalids);
    if (!isValid) {
      return;
    }

    registerMutation.mutate({
      auth,
      email,
      firstName,
      lastName,
      password,
    });
  };

  const isSubmittingRegister = registerMutation.isLoading;
  const registerButtonText = isSubmittingRegister ? 'Registering...' : 'Register';

  const getErrorMessage = () => {
    if (error) {
      return error;
    }
    if (!registerMutation.error) {
      return null;
    }
    // `ApiError` from `post()`, `Error` from `auth.signin()`
    const mutationError = (registerMutation.error as ApiError | Error);
    if ('message' in mutationError) {
      return mutationError.message;
    } else if ('error' in mutationError) {
      return mutationError.error;
    }
    return 'Unknown Error';
  };

  const errorMessage = getErrorMessage();

  if (registerMutation.isSuccess) {
    return <Navigate to={ from } replace />;
  }

  return (
    <div>
      <Row className="justify-content-center mt-5">
        <Col sm="6" lg="4">
          {errorMessage && 
            <Alert variant="danger">
              {errorMessage}
            </Alert>
          }
          <Form className="mb-3" noValidate onSubmit={submitRegister}>
            <Form.Group className="mb-3" controlId="register-first-name">
              <Form.Control required type="text" placeholder="First Name" isInvalid={invalids.firstName} onChange={(e) => setFirstName(e.target.value)}/>
              <Form.Control.Feedback type="invalid">First name is required.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="register-last-name">
              <Form.Control required type="text" placeholder="Last Name" isInvalid={invalids.lastName} onChange={(e) => setLastName(e.target.value)}/>
              <Form.Control.Feedback type="invalid">Last name is required.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="register-email">
              <Form.Control required type="email" placeholder="Email" isInvalid={invalids.email} onChange={(e) => setEmail(e.target.value)}/>
              <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="register-password">
              <Form.Control required type="password" placeholder="Password" isInvalid={invalids.password} onChange={(e) => onChangePassword(e.target.value)}/>
              <Form.Text><PasswordStrengthMeter className="mt-1" score={passwordScore} /></Form.Text>
              <Form.Control.Feedback type="invalid">Password is not strong enough.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="register-confirm-password">
              <Form.Control required type="password" placeholder="Confirm Password" isInvalid={invalids.confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
              <Form.Control.Feedback type="invalid">Does not match password.</Form.Control.Feedback>
            </Form.Group>
            <Form.Check type="checkbox" className="mb-3">
              <Form.Check.Input id="register-tos" type="checkbox" isInvalid={invalids.isTosAgree} onChange={(e) => setIsTosAgree(e.target.checked)}/>
              {/* TODO: Update Terms of Service Link */}
              <Form.Check.Label>I agree to the <a href="https://www.evme.com/terms">Terms of Service</a>.</Form.Check.Label>
              <Form.Control.Feedback type="invalid">You must agree before registering.</Form.Control.Feedback>
            </Form.Check>
            <Form.Check type="checkbox" className="mb-3">
              <Form.Check.Input id="register-adult" type="checkbox" isInvalid={invalids.isAdult} onChange={(e) => setIsAdult(e.target.checked)}/>
              <Form.Check.Label>I am at least 18 years old.</Form.Check.Label>
              <Form.Control.Feedback type="invalid">You must be at least 18 years old to register.</Form.Control.Feedback>
            </Form.Check>
            <Button type="submit" className="w-100" disabled={ isSubmittingRegister }>
              { registerButtonText }
            </Button>
          </Form>
          <p className="text-center">Already a user? <Link to="/login">Sign In</Link>.</p>
        </Col>
      </Row>
    </div>
  )

}

export default RegisterRoute;