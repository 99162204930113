import { Navigate, Outlet, useLocation } from 'react-router-dom';

function QuestionnairesRootRoute() {
  const { pathname } = useLocation();

  // Let's call this an invalid path and just send you back to the home page.
  // Alternatively, we could try to fetch the current questionnaire and get then send
  // the use there.
  if (pathname === '/questionnaires') {
    return <Navigate to="/" />;
  }

  return (
    <Outlet />
  )

}

export default QuestionnairesRootRoute;