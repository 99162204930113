import { Navigate } from 'react-router-dom';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import RegistrationConsentContainer from '../../components/registration/RegistrationConsentContainer';
import HippaConsentBody from '../../components/registration/HipaaConsentBody';
import useUpdateConsentMutation from '../../utils/useUpdateConsentMutation';

function HipaaConsentRoute() {
  const auth = useAuth();

  const updateConsentMutation = useUpdateConsentMutation();

  const submit = () => {
    const patientId = auth.user?.id;
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    updateConsentMutation.mutate({patientId, consentPath: 'hipaa-consent'});
  }

  if (updateConsentMutation.isSuccess) {
    return <Navigate to="/registration" />;
  }

  return (
    <RegistrationConsentContainer
      title={ 'HIPAA' }
      canAgree={ true }
      isSubmitting={ updateConsentMutation.isLoading }
      submit={ submit }>
      <HippaConsentBody />
    </RegistrationConsentContainer>
  );
}

export default HipaaConsentRoute;