import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import RbNavbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';


function Navbar({
  isAuthenticated,
  signOut,
}: {
  isAuthenticated: boolean,
  signOut: () => void,
}) {
  const getSigninOrSignout = () => {
    if (isAuthenticated) {
      return (
        <Nav>
          <NavLink to="/settings" className="nav-link me-2">Settings</NavLink>
          <Button onClick={signOut} size="sm" variant="outline-danger">Sign Out</Button>
        </Nav>
      )
    } else {
      return (
        <Nav>
          <Nav.Item>
            <NavLink to="/login" className="nav-link">Sign In</NavLink>
          </Nav.Item>
        </Nav>
      )
    }
  }
  const signinout = getSigninOrSignout();

  return (
    <RbNavbar bg="light" expand="lg">
      <Container fluid>
        <LinkContainer to="/">
          {/* TODO: actual logo */}
          <RbNavbar.Brand>Evme</RbNavbar.Brand>
        </LinkContainer>
        <RbNavbar.Toggle aria-controls="basic-navbar-nav" />
        <RbNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          { signinout }
        </RbNavbar.Collapse>
      </Container>
    </RbNavbar>
  );
}

export default Navbar;