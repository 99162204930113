import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';


type CheckedState = {
  infoCorrect: boolean,
  timeFrame: boolean,
  haveSmartphone: boolean,
  understandSchedule: boolean,
  adhesionInstructions: boolean,
  keepMarkings: boolean,
  nonRefundable: boolean,
  understandRisks: boolean,
}

function TreatmentConsentBody({
  setAreAllChecked,
}: {
  setAreAllChecked: (allChecked: boolean) => void,
}) {
  const [checkedState, setCheckedState] = useState<CheckedState>({
    infoCorrect: false,
    timeFrame: false,
    haveSmartphone: false,
    understandSchedule: false,
    adhesionInstructions: false,
    keepMarkings: false,
    nonRefundable: false,
    understandRisks: false,
  });
  const updateCheckedState = (stateChange: Partial<CheckedState>) => {
    const newState = {
      ...checkedState,
      ...stateChange,
    };
    const areAllChecked = Object.values(newState).every((val) => val);
    setCheckedState(newState);
    setAreAllChecked(areAllChecked);
  }
  const checkboxes = [{
    id: 'info-correct',
    label: (<span>All information provided including medication lists are accurate and complete, to the best of my knowledge.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({infoCorrect: state});
    },
  }, {
    id: 'time-frame',
    label: (<span>I can commit to the testing time frame and I understand the schedule.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({timeFrame: state});
    },
  }, {
    id: 'have-smartphone',
    // TODO: Update capability...
    label: (<span>I have a smartphone with XXX capability - camera, microphone with ability to do virtual visits. I understand how to use the camera and microphone in my smartphone or I have someone who can help me with <strong>every</strong> visit who understands how to use these features.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({haveSmartphone: state});
    },
  }, {
    id: 'understand-schedule',
    label: (<span>I understand that appointments for virtual visits CANNOT be rescheduled once the patch has been placed. If the patch has not been placed yet, the full set [all 3] of virtual visits can be rescheduled together ONCE.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({understandSchedule: state});
    },
  }, {
    id: 'adhesion-instructions',
    label: (<span>I will follow instructions to ensure proper patch adhesion for 2 days.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({adhesionInstructions: state});
    },
  }, {
    id: 'keep-markings',
    label: (<span>I will keep markings on skip [even if it means re-applying marker] in order to make sure markings are kept on until the 2nd reading.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({keepMarkings: state});
    },
  }, {
    id: 'non-refundable',
    label: (<span>I understand that the patch system is non-refundable once received</span>),
    updateState: (state: boolean) => {
      updateCheckedState({nonRefundable: state});
    },
  }, {
    id: 'understand-risks',
    label: (<span>I have read and understood the procedures as well as potential risks noted above. I agree to take this test at my own risk.</span>),
    updateState: (state: boolean) => {
      updateCheckedState({understandRisks: state});
    },
  }].map(({id, label, updateState}) => {
    return (
      <Form.Check key={id} type="checkbox" className="mb-3">
        <Form.Check.Input id={ id } type="checkbox" onChange={(e) => updateState(e.target.checked)} />
        <Form.Check.Label>{ label }</Form.Check.Label>
      </Form.Check>
    );
  });

  return (
    <div>
      <Alert variant="danger">
        TODO: REVIEW COPY
      </Alert>
      <p>
        Prior to starting patch testing, we want to make sure that you are aware
        of the process and potential risks associated with taking this test. If
        you have any questions, please contact our customer service team. 
      </p>
      <p>
        <strong>Review of patch testing schedule:</strong>
      </p>
      <p>
        3 virtual video appointments (lasting 5-10 minutes) will be scheduled by you as follows:
      </p>
      <ul>
        <li>1st visit: patch placement (kept on for 48 hours without removal)</li>
        <li>2nd visit (48 hours later): patch removal and reading #1</li>
        <li>3rd visit (48-72 hours later): patch reading #2</li>
      </ul>
      <p>
        For the duration of patch testing (1st visit until 3rd visit, 4-5 days
        later), in order to ensure proper patch adhesion, you will a) limit
        physical activity while the patch is on [nothing that will cause
        excessive sweating] and b) avoid baths or soaking in water (swimming,
        hot tubbing, ice baths, etc.).
      </p>
      <p>
        <strong>Potential side effects of patch testing:</strong>
      </p>
      <ul>
        <li>Skin burning</li>
        <li>Irritation from the tape/adhesive in the test</li>
        <li>Redness</li>
        <li>Itchiness</li>
        <li>Temporary skin discoloration at patch site</li>
        <li>Persistent reactions, usually lasting less than a month</li>
        <li>Worsening of pre-existing skin rash</li>
        <li>
          <strong>Rare:</strong> skin infections, acute allergic reaction like
          anaphylaxis, “angry back” syndrome - when skin is over-reactive and
          many of the patch areas become red and reactive causing a false
          positive effect, contact urticaria - a hive at the site of the patch
          test.
        </li>
      </ul>
      <hr />
      { checkboxes }
    </div>
  )
}

export default TreatmentConsentBody;