import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { get } from 'webapp-common/api/RestApi';
import Patient, { hasPatientFilledOutAdditionalInformation, isPatientFullyRegistered } from 'webapp-common/models/Patient';
import { useAuth } from 'webapp-common/auth/AuthProvider';

import Loading from '../../components/Loading';
import { getPatientByIdQueryKey } from '../../utils/query-keys';


function RegistrationRootRoute() {
  const auth = useAuth();
  const location = useLocation();

  const patientId = auth.user?.id;

  const patientQueryKey = getPatientByIdQueryKey(patientId);
  const {
    data: patient,
  } = useQuery(patientQueryKey, async () => {
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    return await get<Patient>(`/patients/${patientId}`);
  });

  if (!auth.user) {
    // This can't happen...
    return <Navigate to="/" />
  }

  /**
   * 1. If user is already fully registered, then redirect to home.
   * 2. Otherwise check patient and go to appropriate registration page.
   */

  if (!patient) {
    return <Loading />;
  }

  if (isPatientFullyRegistered(patient)) {
    return <Navigate to="/" />;
  }

  // If they just when to `/registration`, then figure out which step
  // they need to go to.
  if (location.pathname === '/registration') {
    const getRegistrationStep = (p: Patient): string | null => {
      if (!hasPatientFilledOutAdditionalInformation(p)) {
        return 'additional-information';
      } else if (!p.hipaa_consent_at) {
        return 'hipaa-consent';
      } else if (!p.treatment_consent_at) {
        return 'treatment-consent';
      } else if (!p.agree_technical_requirements_at) {
        return 'technical-requirements';
      } else if (!p.agree_logistical_requirements_at) {
        return 'logistical-requirements';
      } else if (!p.agree_medical_requirements_at) {
        return 'medical-requirements';
      }
      return null;
    }
    const step = getRegistrationStep(patient);
    const path = step ? `/registration/${step}` : '/';
    return <Navigate to={path} />;
  }

  return (
    <Outlet />
  );
}

export default RegistrationRootRoute;