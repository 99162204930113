import { FormEventHandler, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import QuestionnaireInputMedication from '../../types/QuestionnaireInputMedication';


function QuestionnaireAddMedicationForm({
  addMedication,
}: {
  addMedication: (med: QuestionnaireInputMedication) => Promise<void>,
}) {
  const [inputMedication, setInputMedication] = useState<QuestionnaireInputMedication>({
    name: '',
    dose: '',
    frequency: '',
    reason: '',
    notes: '',
  });

  const updateInputMedication = (update: Partial<QuestionnaireInputMedication>) => {
    setInputMedication({...inputMedication, ...update});
  };

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    await addMedication(inputMedication);
  };

  const formGroups = [{
    id: 'name',
    type: 'text',
    label: 'Name of Medication',
    setValue: (val: string) => updateInputMedication({name: val}),
  }, {
    id: 'dose',
    type: 'text',
    label: 'Dose',
    setValue: (val: string) => updateInputMedication({dose: val}),
  }, {
    id: 'frequency',
    type: 'text',
    label: 'Frequency',
    setValue: (val: string) => updateInputMedication({frequency: val}),
  }, {
    id: 'reason',
    type: 'textarea',
    label: 'Why do you take this?',
    setValue: (val: string) => updateInputMedication({reason: val}),
  }, {
    id: 'notes',
    type: 'textarea',
    label: 'Additional notes',
    setValue: (val: string) => updateInputMedication({notes: val}),
  }].map((elem) => {
    const asElement = elem.type === 'textarea' ? 'textarea' : 'input';
    const asType = elem.type === 'text' ? 'text': undefined;
    return (
      <Form.Group key={elem.id} controlId={elem.id} className="mb-3">
        <Form.Label>{elem.label}</Form.Label>
        <Form.Control required type={asType} as={asElement} onChange={(e) => elem.setValue(e.target.value)} />
      </Form.Group>
    );
  });

  return (
    <Form noValidate onSubmit={onSubmit}>
      {formGroups}
      <Button type="submit" className="w-100">Add Medication</Button>
    </Form>
  );
};


export default QuestionnaireAddMedicationForm;