import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import Questionnaire from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';
import QuestionnaireState, { getQuestionnaireState } from 'webapp-common/models/QuestionnaireState';

import QuestionnaireResponsesTable from './QuestionnaireResponsesTable';


function QuestionnaireInformationAlert({
  questionnaireState,
  reviewNote,
}: {
  questionnaireState: QuestionnaireState,
  reviewNote: string | null,
}) {
  switch (questionnaireState) {
    case QuestionnaireState.Submitted:
      return (
        <Alert variant="secondary">
          <p>Thank you for submitting your responses!</p>
          <p>An Evme provider will review your questionnaire within the next 48 hours to determine your eligibility.</p>
          <p>You will receive an email notification when the review is complete.</p>
          <p>You can find your questionnaire below.</p>
        </Alert>
      );
    case QuestionnaireState.Approved:
      return (
        <Alert variant="success">
          <p>Based on the answers you provided in the questionnaire, you are deemed a great candidate for our patch test.</p>
          {/* TODO: Figure out where these links go... */}
          <p>Please also read the <a href="#">User Instructions for PCH-415</a> and <a href="#">Contract/Consent for the tests</a>.</p>
        </Alert>
      );
    case QuestionnaireState.Denied:
      return (
        <Alert variant="danger">
          <p>
            Based on the answers you provided in the Questionnaire, you are unfortunately
            not a candidate for our patch test. You were disqualified due to this reason:
          </p>
          <p><em>{ reviewNote }</em></p>
          {/* TODO: Figure out where this link goes... */}
          <p>Please see the <a href="#">list of exclusion criteria</a>.</p>
        </Alert>
      );
    default:
      return (<div></div>);
  }
}


// TODO: Actually figure out how this looks...
function QuestionnaireSubmitted({
  questionnaire,
  medications,
}: {
  questionnaire: Questionnaire,
  medications: QuestionnaireMedication[],
}) {
  const questionnaireState = getQuestionnaireState(questionnaire);
  return (
    <Row className="justify-content-center mt-5 mb-5">
      <Col sm="10" lg="8">
        <Card className="mb-3 shadow">
          <Card.Body>
            <div className="m-5">
              <QuestionnaireInformationAlert
                questionnaireState={ questionnaireState }
                reviewNote={ questionnaire.review_note }
                />
              <QuestionnaireResponsesTable
                questionnaire={questionnaire}
                medications={medications}
                showEditLink={false}
                />
            </div>
          </Card.Body>
        </Card>
        <div className="position-relative mt-4" style={{height: '50px'}}>
          <div className="position-absolute top-50 start-50 translate-middle">
            <Link to="/" className="btn btn-outline-secondary btn-lg">Done</Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};


export default QuestionnaireSubmitted;