import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';


interface ProgressStep {
  isActive: boolean,
  isComplete: boolean,
  link: string,
}


function QuestionnaireProgressBar({
  steps,
  className,
  completeVariant = 'success',
  incompleteVariant = 'secondary',
}: {
  steps: ProgressStep[],
  className: string,
  completeVariant?: string,
  incompleteVariant?: string,
}) {
  const stepsWithIndex = steps.map((step, index) => ({...step, index}));

  const numSteps = stepsWithIndex.length;

  const pbars = stepsWithIndex.filter(({index}) => index < (numSteps - 1))
    .map((startStep, idx) => {
      const endStep = stepsWithIndex[idx + 1];

      const startIndex = startStep.index;
      const endIndex = startIndex + 1;

      const isStartComplete = startStep.isComplete;
      const isEndComplete = endStep.isComplete;
      const variant = (isStartComplete && isEndComplete) ? completeVariant : incompleteVariant;

      const start = Math.round((startIndex / (numSteps - 1)) * 100);
      const end = Math.round((endIndex / (numSteps - 1)) * 100);
      const pbarStyle = {
        height: '2px',
        left: `${start}%`,
        width: `${end - start}%`,
      }
      const pbarKey = `pbar-${startIndex}`;
      return <ProgressBar className="position-absolute" key={pbarKey} now={100} variant={variant} visuallyHidden style={pbarStyle} />;
    });

  const buttons = stepsWithIndex.map(({index, isComplete, link}) => {
    const variant = isComplete ? completeVariant : incompleteVariant;
    const left = Math.round((index / (numSteps - 1)) * 100);
    const leftStyle = `${left}%`;
    const style = {
      width: '2rem',
      height: '2rem',
      left: leftStyle,
    };
    const linkKey = `link-${index}`;
    const className = `btn btn-${variant} btn-sm position-absolute top-0 translate-middle rounded-pill`;
    return <Link to={link} key={linkKey} className={className} style={style} />;
  });

  const activeButtonBorders = stepsWithIndex.filter(({isActive}) => isActive === true)
    .map((step) => {
      const stepLeft = Math.round((step.index / (numSteps - 1)) * 100);
      const activeButtonBorderStyle = {
        width: '2.5rem',
        height: '2.5rem',
        left: `${stepLeft}%`,
      };
      const activeButtonBorderColor = step.isComplete ? completeVariant : incompleteVariant;
      const activeButtonBorderClass = `position-absolute top-0 translate-middle rounded-pill border border-${activeButtonBorderColor} border-5 opacity-50`;
      const key = `active-${step.index}`;
      return <div key={key} className={activeButtonBorderClass} style={activeButtonBorderStyle}></div>;
    });

  // Based on https://getbootstrap.com/docs/5.1/utilities/position/#examples
  return (
    <div className={className}>
      {pbars}
      {buttons}
      {activeButtonBorders}
    </div>
  )
};

export default QuestionnaireProgressBar;