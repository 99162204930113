import { FormEventHandler, forwardRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { post } from 'webapp-common/api/RestApi';
import { useAuth } from 'webapp-common/auth/AuthProvider';
import Patient from 'webapp-common/models/Patient';
import PostalAddress from 'webapp-common/models/PostalAddress';

import NaiveDate from '../../types/NaiveDate';
import { US_STATES } from '../../utils/us-states';
import { getPatientByIdQueryKey, getPatientShippingAddressQueryKey } from '../../utils/query-keys';

interface Address {
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip: string,
}

interface UpdateBirthdateParams {
  patientId: string,
  birthdate: NaiveDate,
}

const updateBirthdate = async ({ patientId, birthdate }: UpdateBirthdateParams) => {
  const path = `/patients/${patientId}/birthdate`;
  const body = {
    birthdate: birthdate.toString(),
  };
  return await post<Patient>(path, body);
};

interface UpdatePhoneNumberParams {
  patientId: string,
  // TODO: Make this a `PhoneNumber` type?
  phoneNumber: string,
}
const updatePhoneNumber = async ({ patientId, phoneNumber }: UpdatePhoneNumberParams) => {
  const path = `/patients/${patientId}/phone-number`;
  const body = {
    phone_number: phoneNumber,
  };
  return await post<Patient>(path, body);
};

type InputPostalAddress = {
  street1: string,
  street2?: string | null,
  locality: string,
  region: string,
  country: string,
  postal_code: string,
}
interface UpdateShippingAddressParams {
  patientId: string,
  shippingAddress: InputPostalAddress,
}
const updateShippingAddress = async ({ patientId, shippingAddress }: UpdateShippingAddressParams) => {
  const path = `/patients/${patientId}/shipping-address`;
  return await post<PostalAddress>(path, shippingAddress);
}

function AdditionalInformationRoute() {
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const updatePatientOnSuccess = (patient: Patient, {patientId}: {patientId: string}) => {
    const queryKey = getPatientByIdQueryKey(patientId);
    queryClient.setQueryData(queryKey, patient);
  };
  const updateBirthdateMutation = useMutation(updateBirthdate, {
    onSuccess: updatePatientOnSuccess,
  });
  const updatePhoneNumberMutation = useMutation(updatePhoneNumber, {
    onSuccess: updatePatientOnSuccess,
  });
  const updateShippingAddressMutation = useMutation(updateShippingAddress, {
    onSuccess(postalAddress, {patientId}: {patientId: string}) {
      queryClient.setQueryData<PostalAddress>(getPatientShippingAddressQueryKey(patientId), postalAddress);
      // This is a little weird to update the `Patient` manually,
      // but meh, seems okay.
      const patientByIdQueryKey = getPatientByIdQueryKey(patientId);
      const currentPatient = queryClient.getQueryData<Patient>(patientByIdQueryKey);
      if (!currentPatient) {
        return;
      }
      queryClient.setQueryData<Patient>(patientByIdQueryKey, (patient) => {
        // We need to specify this `as Patient` because TS thinks `patient` could be `undefined`,
        // but we already checked that above with `getQueryData`.
        return {
          ...patient,
          shipping_address_id: postalAddress.id,
        } as Patient;
      });
    },
  });

  // Format (from input type="text"): `YYYY-MM-DD`.
  const [birthdateStr, setBirthdateStr] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [shippingAddress, setShippingAddress] = useState<Address>({
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [invalids, setInvalids] = useState({
    birthdate: false,
    phoneNumber: false,
    street1: false,
    street2: false,
    city: false,
    state: false,
    zip: false,
  });

  const isAnyRequiredFieldEmpty = (
    !birthdateStr ||
    !formattedPhoneNumber ||
    !shippingAddress.street1 ||
    !shippingAddress.city ||
    !shippingAddress.state ||
    !shippingAddress.zip
  );

  const getNewFormattedPhoneNumber = (phoneNumber: string): string => {
    // Only format it at the very end.
    if (phoneNumber.length < 10) {
      return phoneNumber;
    }
    const groups = [
      phoneNumber.substring(0, 3),
      phoneNumber.substring(3, 6),
      phoneNumber.substring(6, 10),
    ];
    return `(${groups[0]}) ${groups[1]}-${groups[2]}`;
  }

  const updateFormattedPhoneNumber = (newPhoneNumber: string) => {
    const phoneNumber = newPhoneNumber.replaceAll(/[^0-9]/g, '');
    const newFormattedPhoneNumber = getNewFormattedPhoneNumber(phoneNumber);
    setFormattedPhoneNumber(newFormattedPhoneNumber);
  };

  const setShippingAddressPart = (update: Partial<Address>) => {
    setShippingAddress({
      ...shippingAddress,
      ...update,
    });
  }

  const submit: FormEventHandler = async (event) => {
    event.preventDefault();
    const phoneNumber = formattedPhoneNumber.replaceAll(/[^0-9]/g, '');
    const birthdate = NaiveDate.tryFromString(birthdateStr);

    const newInvalids = {
      birthdate: !birthdate,
      phoneNumber: !phoneNumber || phoneNumber.length !== 10,
      street1: !shippingAddress.street1,
      street2: false,
      city: !shippingAddress.city,
      state: !shippingAddress.state,
      zip: !shippingAddress.zip || !/^\d{5}(-\d{4})?$/.test(shippingAddress.zip),
    };
    const isValid = !Object.values(newInvalids).includes(true);
    setInvalids(newInvalids);

    if (!isValid || !birthdate /* to help TS */) {
      return;
    }
    const patientId = auth.user?.id;
    if (!patientId) {
      // Should not happen!
      return;
    }
    setIsSubmitting(true);
    await updateBirthdateMutation.mutateAsync({patientId, birthdate});
    await updatePhoneNumberMutation.mutateAsync({patientId, phoneNumber});
    const shippingPostalAddress = {
      street1: shippingAddress.street1,
      street2: shippingAddress.street2 || null,
      locality: shippingAddress.city,
      region: shippingAddress.state,
      country: 'US',
      postal_code: shippingAddress.zip,
    }
    await updateShippingAddressMutation.mutateAsync({patientId, shippingAddress: shippingPostalAddress});
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
    navigate('/registration');
  }

  // TODO: 50 states + DC? What about PR, etc?
  const stateOptions = US_STATES.map(({code, label}) => {
    return <option key={code} value={code}>{label}</option>;
  });

  const shippingAddressPopover = (
    <Popover id="shipping-address-popover">
      <Popover.Body>
        This is where we will ship your patch.
        We also require that you apply the patch and do both readings in the state specified.
        This is a requirement because the provider matched to you will be licensed in that state.
      </Popover.Body>
    </Popover>
  );
  const ShippingAddressInfoCircle = forwardRef((props: any, ref: any) => {
    // `props` contains `onFocus`, `onBlur`, `onMouseOver`, `onMouseOut` functions.
    // `ref` appears to be a reference to the `OverlayTrigger`.
    // We wrap in a `Button` so `focus` works.
    return (
      <Button variant="link" size="sm" ref={ref} {...props}>
        <FaInfoCircle className="text-black-50"/>
      </Button>
    );
  });

  const isSubmitDisabled = isSubmitting || isAnyRequiredFieldEmpty;
  const submitText = isSubmitting ? 'Submitting...' : 'Continue';

  return (
    <div>
      <Row className="justify-content-center mt-5 mb-5">
        <Col sm="6" lg="4">
          <p className="text-center">Tell us a little more about yourself.</p>
          <Form noValidate onSubmit={submit}>
            <Form.Group className="mb-3" controlId="birthdate">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control required type="date" isInvalid={invalids.birthdate} onChange={(e) => setBirthdateStr(e.target.value) } />
              <Form.Control.Feedback type="invalid">Invalid or missing Date of Birth.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone-number">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control required type="tel" isInvalid={invalids.phoneNumber} value={formattedPhoneNumber} onChange={(e) => updateFormattedPhoneNumber(e.target.value)} />
              <Form.Control.Feedback type="invalid">Invalid or missing Phone Number.</Form.Control.Feedback>
            </Form.Group>
            <Form.Label>
              Shipping Address
            </Form.Label>
            <div className="float-end">
              <OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={shippingAddressPopover}>
                {/* <FaInfoCircle className="text-black-50" /> */}
                {<ShippingAddressInfoCircle />}
              </OverlayTrigger>
            </div>
            <Form.Group className="mb-3" controlId="street1">
              <Form.Control required type="text" placeholder="Street 1" isInvalid={invalids.street1} onChange={(e) => setShippingAddressPart({street1: e.target.value})} />
              <Form.Control.Feedback type="invalid">Invalid or missing Street 1.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="street2">
              <Form.Control required type="text" placeholder="Street 2" isInvalid={invalids.street2} onChange={(e) => setShippingAddressPart({street2: e.target.value})} />
              <Form.Control.Feedback type="invalid">Invalid or missing Street 2.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="city">
              <Form.Control required type="text" placeholder="City" isInvalid={invalids.city} onChange={(e) => setShippingAddressPart({city: e.target.value})} />
              <Form.Control.Feedback type="invalid">Invalid or missing City.</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col sm={8}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Select isInvalid={invalids.state} onChange={(e) => setShippingAddressPart({state: e.target.value})}>
                    <option value={''}>State</option>
                    {stateOptions}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Invalid or missing State.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3" controlId="zipcode">
                  <Form.Control required type="text" placeholder="Zip" pattern="^\d{5}(-\d{4})$" isInvalid={invalids.zip} onChange={(e) => setShippingAddressPart({zip: e.target.value})} />
                  <Form.Control.Feedback type="invalid">Invalid or missing Zip Code.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" className="w-100" disabled={isSubmitDisabled}>{submitText}</Button>
          </Form>
        </Col>
      </Row>
    </div>
  )

}

export default AdditionalInformationRoute;