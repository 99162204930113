import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { FaTimes } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import { useAuth } from 'webapp-common/auth/AuthProvider';
import { del, get, post } from 'webapp-common/api/RestApi';
import PatchFlowVisit from 'webapp-common/models/PatchFlowVisit';
import PatchFlowVisitType from 'webapp-common/models/PatchFlowVisitType';
import PatchFlowVisitAvailability, { AvailabilitySlot } from 'webapp-common/models/PatchFlowVisitAvailability';
import PostalAddress from 'webapp-common/models/PostalAddress';
import UsState from 'webapp-common/types/UsState';

import Loading from '../../components/Loading';
import { getPatchFlowVisitAvailabilityQueryKey, getPatchFlowVisitsByPatchFlowIdQueryKey, getPatientShippingAddressQueryKey } from '../../utils/query-keys';
import { getVisitTimeDisplayString } from '../../utils/patch-flow-visit';


interface FetchAvailabilityParams {
  state: UsState,
  after: Date,
  before: Date,
}
const fetchAvailability = async ({state, after, before}: FetchAvailabilityParams) => {
  const body = {
    state: state as string,
    after: after.toISOString(),
    before: before.toISOString(),
  };
  return await get<PatchFlowVisitAvailability[]>('/patch-flow-visits/availability', body);
};

interface ProviderWithId {
  id: string,
}

interface AvailableSlot {
  provider: ProviderWithId,
  startTime: Date,
}

const QUARTER_HOUR_IN_MINUTES = 15;
const QUARTER_HOUR_IN_MILLISECONDS = QUARTER_HOUR_IN_MINUTES * 60 * 1000;
const MIN_HOURS_UNTIL_NEXT_APPOINTMENT = 48;
const MAX_HOURS_UNTIL_NEXT_APPOINTMENT = 50;


const getNextQuarterHour = (date: Date): Date => {
  const d = new Date(date);
  const millis = d.getMilliseconds();
  const seconds = d.getSeconds();
  if (millis > 0 || seconds > 0) {
    // Advance until next minute.
    d.setMilliseconds(0);
    d.setSeconds(0);
    d.setMinutes(d.getMinutes() + 1);
  }
  const minutes = d.getMinutes();
  const minutesPastQuarterHour = minutes % QUARTER_HOUR_IN_MINUTES;
  const minutesUntilNextQuarterHour = QUARTER_HOUR_IN_MINUTES - minutesPastQuarterHour;
  d.setMinutes(minutes + minutesUntilNextQuarterHour);
  return d;
}
const addMinutes = (date: Date, minutes: number): Date => {
  const d = new Date(date);
  d.setMinutes(d.getMinutes() + minutes);
  return d;
};
const addHours = (date: Date, hours: number): Date => {
  const d = new Date(date);
  d.setHours(d.getHours() + hours);
  return d;
};
const splitIntoVisitStartTimes = (startTime: Date, endTime: Date) => {
  const visitStartTimes = [];
  const lastStartTimeBeforeEndTime = addMinutes(endTime, -QUARTER_HOUR_IN_MINUTES);
  let nextStartTime = getNextQuarterHour(startTime);
  while (nextStartTime <= lastStartTimeBeforeEndTime) {
    visitStartTimes.push(nextStartTime);
    nextStartTime = addMinutes(nextStartTime, QUARTER_HOUR_IN_MINUTES);
  }
  return visitStartTimes;
}
const getAllVisitStartTimes = (slots: AvailabilitySlot[]): Date[] => {
  return slots.map((slot) => {
    const startTime = new Date(slot.start_time);
    const endTime = new Date(slot.end_time);
    return splitIntoVisitStartTimes(startTime, endTime);
  }).flat();
};

// 48 - 50 hours!
const getFollowupSlots = (slots: AvailableSlot[], slotIdx: number): number[] => {
  const slot = slots[slotIdx];
  const minStartTime = addHours(slot.startTime, MIN_HOURS_UNTIL_NEXT_APPOINTMENT);
  const maxStartTime = addHours(slot.startTime, MAX_HOURS_UNTIL_NEXT_APPOINTMENT);
  const followups = [];
  for (let i = slotIdx + 1; i < slots.length; i++) {
    const s = slots[i];
    if (s.startTime < minStartTime) {
      continue;
    }
    if (s.startTime > maxStartTime) {
      break;
    }
    if (slot.provider.id === s.provider.id) {
      followups.push(i);
    }
  }
  return followups;
};
const hasFollowupSlot = (slots: AvailableSlot[], slotIdx: number): boolean => {
  const slot = slots[slotIdx];
  const minStartTime = addHours(slot.startTime, MIN_HOURS_UNTIL_NEXT_APPOINTMENT);
  const maxStartTime = addHours(slot.startTime, MAX_HOURS_UNTIL_NEXT_APPOINTMENT);
  for (let i = slotIdx + 1; i < slots.length; i++) {
    const s = slots[i];
    if (s.startTime < minStartTime) {
      continue;
    }
    if (s.startTime > maxStartTime) {
      return false;
    }
    if (slot.provider.id === s.provider.id) {
      return true;
    }
  }
  return false;
};
const isPossiblePlacementSlot = (slots: AvailableSlot[], slotIdx: number): boolean => {
  const readingOneFollowupIdxs = getFollowupSlots(slots, slotIdx);
  return readingOneFollowupIdxs.some((readingOneIdx) => {
    return hasFollowupSlot(slots, readingOneIdx);
  });
};
// Does _not_ assume `provider` is the same for all `slots`
const getPossiblePlacementSlots = (slots: AvailableSlot[]): AvailableSlot[] => {
  return slots.filter((slot, idx) => isPossiblePlacementSlot(slots, idx));
}
const areSlotsEqual = (a: AvailableSlot, b: AvailableSlot): boolean => {
  return a.provider.id === b.provider.id && a.startTime.getTime() === b.startTime.getTime();
};

const getPossibleReadingOneSlots = (slots: AvailableSlot[], placementSlotIdx: number): AvailableSlot[] => {
  const readingOneSlotIdxs = getFollowupSlots(slots, placementSlotIdx);
  const possibleReadingOneSlotIdxs = readingOneSlotIdxs.filter((slotIdx) => hasFollowupSlot(slots, slotIdx));
  return possibleReadingOneSlotIdxs.map((slotIdx) => slots[slotIdx]);
};
const getPossibleReadingTwoSlots = (slots: AvailableSlot[], readingOneSlotIdx: number): AvailableSlot[] => {
  const readingTwoSlotIdxs = getFollowupSlots(slots, readingOneSlotIdx);
  return readingTwoSlotIdxs.map((slotIdx) => slots[slotIdx]);
};

// Assumes `slots` are sorted by `startTime`.
const getUniqueStartTimesFromSlots = (slots: AvailableSlot[]): Date[] => {
  let lastStartTimeMs: number | null = null;
  const uniqueDates: Date[] = [];
  slots.forEach(({startTime}) => {
    const startTimeMs = startTime.getTime();
    if (!lastStartTimeMs || lastStartTimeMs !== startTimeMs) {
      lastStartTimeMs = startTimeMs;
      uniqueDates.push(startTime);
    }
  });
  return uniqueDates;
}

class AvailableSlots {
  slots: AvailableSlot[]

  constructor(slots: AvailableSlot[]) {
    // assumes `slots` is sorted by `startTime` ascending...
    this.slots = slots;
  }

  getPossiblePlacementStartTimes(): Date[] {
    const slots = getPossiblePlacementSlots(this.slots);
    return getUniqueStartTimesFromSlots(slots);
  }

  getPossibleReadingOneStartTimes(placementStartTime: Date): Date[] {
    const possibleReadingOneSlots = this._getFollowupSlots(placementStartTime);
    const readingOneSlots = possibleReadingOneSlots.filter((slot) => {
      return this._hasFollowupSlot(slot);
    });
    return getUniqueStartTimesFromSlots(readingOneSlots);
  }

  getPossibleReadingTwoStartTimes(readingOneStartTime: Date): Date[] {
    const readingTwoSlots = this._getFollowupSlots(readingOneStartTime);
    return getUniqueStartTimesFromSlots(readingTwoSlots);
  }

  _getFollowupSlots(startTime: Date): AvailableSlot[] {
    const providers = this._getProvidersForStartTime(startTime);
    const minStartTime = addHours(startTime, MIN_HOURS_UNTIL_NEXT_APPOINTMENT);
    const maxStartTime = addHours(startTime, MAX_HOURS_UNTIL_NEXT_APPOINTMENT);
    return this.slots.filter((s) => {
      return s.startTime >= minStartTime && s.startTime <= maxStartTime && providers.some((p) => p.id === s.provider.id);
    });
  }

  _hasFollowupSlot(slot: AvailableSlot): boolean {
    const minStartTime = addHours(slot.startTime, MIN_HOURS_UNTIL_NEXT_APPOINTMENT);
    const maxStartTime = addHours(slot.startTime, MAX_HOURS_UNTIL_NEXT_APPOINTMENT);
    return this.slots.some((s) => {
      return s.startTime >= minStartTime && s.startTime <= maxStartTime && s.provider.id === slot.provider.id;
    });
  }

  getProviderIdsForStartTimes(startTimes: Date[]): string[] {
    const providerIdsForStartTimes = startTimes.map((s) => this._getProvidersForStartTime(s).map((p) => p.id));
    return intersection(providerIdsForStartTimes);
  }

  _getProvidersForStartTime(startTime: Date): ProviderWithId[] {
    const startTimeMs = startTime.getTime();
    return this.slots.filter(({startTime}) => startTime.getTime() === startTimeMs)
      .map(({provider}) => provider);
  }
}

const intersection = (arrays: string[][]): string[] => {
  const reducer = (curSet: string[], elem: string[], idx: number): string[] => {
    if (idx == 0) {
      return [...elem];
    }
    const elemSet = new Set(elem);
    return curSet.filter((e) => elemSet.has(e));
  };
  return arrays.reduce(reducer, []);
};

interface DayStringAndStartTimes {
  dayString: string,
  startTimes: Date[],
}
const getSlotsGroupedByStartDay = (startTimes: Date[]): DayStringAndStartTimes[] => {
  const slotsGroupedByStartDay: DayStringAndStartTimes[] = [];
  let lastStartDayStr: string | null = null;
  startTimes.forEach((startTime) => {
    const startDayStr = moment(startTime).format('ddd, MMM D, YYYY');
    if (startDayStr !== lastStartDayStr) {
      lastStartDayStr = startDayStr;
      slotsGroupedByStartDay.push({
        dayString: startDayStr,
        startTimes: [],
      });
    }
    slotsGroupedByStartDay[slotsGroupedByStartDay.length - 1].startTimes.push(startTime);
  });
  return slotsGroupedByStartDay;
}

function SlotsAccordion({
  availableTimes,
  chosenTime,
  setChosenTime,
}: {
  availableTimes: Date[] | null,
  chosenTime: Date | null,
  setChosenTime: (d: Date) => void,
}) {
  if (!availableTimes) {
    return (
      <p><em>Please select a time for the previous appointment to see available times for this appointment.</em></p>
    );
  }

  const chosenTimeMs = chosenTime ? chosenTime.getTime() : null;
  const isActiveTime = (t: Date): boolean => {
    if (!chosenTimeMs) {
      return false;
    }
    return t.getTime() === chosenTimeMs;
  };

  const availableTimesByStartDay = getSlotsGroupedByStartDay(availableTimes);
  const accordionItems = availableTimesByStartDay.map(({dayString, startTimes}) => {
    return (
      <Accordion.Item key={ dayString } eventKey={ dayString }>
        <Accordion.Header>
          <strong>{ dayString }</strong>
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ListGroup variant="flush">
            {startTimes.map((startTime) => {
              const endTime = new Date(startTime);
              endTime.setMinutes(startTime.getMinutes() + 10);
              const startTimeStr = moment(startTime).format('h:mma');
              const endTimeStr = moment(endTime).format('h:mma');
              return (
                <ListGroup.Item key={ startTime.toISOString() } action active={ isActiveTime(startTime) } onClick={() => setChosenTime(startTime)}>
                  {startTimeStr} - {endTimeStr}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    );
  });
  return (
    <Accordion>
      { accordionItems }
    </Accordion>
  );
}

function ChosenTimeDisplay({
  chosenTime,
  clearTime,
  title,
  active,
  disabled,
  onClick,
}: {
  chosenTime: Date | null,
  clearTime: () => void,
  title: string,
  active?: boolean,
  disabled?: boolean,
  onClick?: () => void,
}) {
  const isActive = active || false;
  const isDisabled = disabled || false;
  if (!chosenTime) {
    return (
      <div>
        <h6>{ title }</h6>
        <Button className="w-100 mb-3 text-start" variant="outline-secondary" active={ isActive } disabled={ isDisabled } onClick={ onClick }>
          <em>Not scheduled</em>
        </Button>
      </div>
    );
  }
  const displayString = getVisitTimeDisplayString(chosenTime);
  return (
    <div>
      <h6>{ title }</h6>
      <ButtonGroup className="w-100 mb-3">
        <Button className="text-start" variant="outline-secondary" active={ isActive } disabled={ isDisabled } onClick={ onClick }>
          <strong>{ displayString }</strong>
        </Button>
        <Button variant="outline-danger" onClick={ clearTime } style={ {maxWidth: '44px'} }>
          <FaTimes/>
        </Button>
      </ButtonGroup>
    </div>
  );
}

interface ChosenTimes {
  placement: Date | null,
  readingOne: Date | null,
  readingTwo: Date | null,
}

enum PatchFlowVisitTypeEnum {
  Placement,
  ReadingOne,
  ReadingTwo,
}

const visitTypeIntoEnum = (visitType: PatchFlowVisitType) => {
  switch (visitType) {
    case 'placement':
      return PatchFlowVisitTypeEnum.Placement;
    case 'reading-one':
      return PatchFlowVisitTypeEnum.ReadingOne;
    case 'reading-two':
      return PatchFlowVisitTypeEnum.ReadingTwo;
  }
};

interface CreatePatchFlowVisitsParams {
  patchFlowId: string,
  providerId: string,
  startTimes: Date[],
}
const createPatchFlowVisits = async ({patchFlowId, providerId, startTimes}: CreatePatchFlowVisitsParams) => {
  const body = {
    patch_flow_id: patchFlowId,
    provider_id: providerId,
    start_times: startTimes,
  };
  return await post<PatchFlowVisit[]>('/patch-flow-visits/bulk', body);
};

interface SuccessMessage {
  success: boolean,
}

const deletePatchFlowVisits = async (patchFlowId: string) => {
  return await del<SuccessMessage>('/patch-flow-visits', {patch_flow_id: patchFlowId});
};


const isCompatibleFollowUpTime = (startTime: Date | null, followUpTime: Date | null): boolean => {
  // null is always compatible...
  if (!followUpTime) {
    return true;
  }
  // if startTime is null (and followUpTime is not), then not compatible
  if (!startTime) {
    return false;
  }
  const minFollowUpTime = addHours(startTime, MIN_HOURS_UNTIL_NEXT_APPOINTMENT);
  const maxFollowUpTime = addHours(startTime, MAX_HOURS_UNTIL_NEXT_APPOINTMENT);
  return followUpTime >= minFollowUpTime && followUpTime <= maxFollowUpTime;
};

const getNextVisitTypeToSelect = (newChosenTimes: ChosenTimes) => {
  if (newChosenTimes.placement === null) {
    return PatchFlowVisitTypeEnum.Placement;
  } else if (newChosenTimes.readingOne === null) {
    return PatchFlowVisitTypeEnum.ReadingOne;
  } else {
    return PatchFlowVisitTypeEnum.ReadingTwo;
  }
}

const getPatchFlowVisitStartTimeByType = (patchFlowVisits: PatchFlowVisit[] | undefined, visitType: PatchFlowVisitTypeEnum) => {
  if (!patchFlowVisits) {
    return null;
  }
  const visit = patchFlowVisits.find((v) => visitTypeIntoEnum(v.visit_type) === visitType);
  if (!visit) {
    return null;
  }
  return new Date(visit.start_time);
};

function PatchFlowSchedulingRoute() {
  const auth = useAuth();
  const { patchFlowId } = useParams();
  const queryClient = useQueryClient();

  const patientId = auth.user?.id as string;

  // NOTE 1: For `startTime`, use 
  // Use start of day and end of day so that requests can be cached
  // (or at least DB queries on the backend).
  // NOTE: Should we allow the patient to update the `startTime` and `endTime`
  // (e.g. to look further into the future)?
  // For now we do not given that we don't want patients to book too far in advance.

  // Use just over 1 hour in future so that patients don't schedule for an imminent appointment.
  // TODO: Is 1 hour enough? Maybe it should be the next day?
  const startTime = moment().endOf('hour').add(1, 'hour').toDate();
  // Use just under 28 days from now.
  // The backend will check that we don't schedule beyond 28 days ahead (which is a bit further out).
  // We use the day boundary so that requests can be cached - if not by the browser then at least
  // by the DB for queries (TODO: consider doing something like this on the backend).
  const endTime = moment().startOf('day').add(28, 'days').toDate();

  const {
    data: patchFlowVisits,
    isLoading: isLoadingPatchFlowVisits,
  } = useQuery(getPatchFlowVisitsByPatchFlowIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    const query = {
      patch_flow_id: patchFlowId,
    };
    return await get<PatchFlowVisit[]>('/patch-flow-visits', query);
  });

  const [visitTypeToSelect, setVisitTypeToSelect] = useState<PatchFlowVisitTypeEnum>(PatchFlowVisitTypeEnum.Placement);
  const [showDelete, setShowDelete] = useState(false);

  const [chosenTimes, setChosenTimes] = useState<ChosenTimes>({
    placement: null,
    readingOne: null,
    readingTwo: null,
  });

  const existingPlacementStartTime = getPatchFlowVisitStartTimeByType(patchFlowVisits, PatchFlowVisitTypeEnum.Placement);
  const existingReadingOneStartTime = getPatchFlowVisitStartTimeByType(patchFlowVisits, PatchFlowVisitTypeEnum.ReadingOne);
  const existingReadingTwoStartTime = getPatchFlowVisitStartTimeByType(patchFlowVisits, PatchFlowVisitTypeEnum.ReadingTwo);
  const resetChosenTimes = () => {
    setChosenTimes({
      placement: existingPlacementStartTime,
      readingOne: existingReadingOneStartTime,
      readingTwo: existingReadingTwoStartTime,
    });
  };

  useEffect(() => {
    // Set the chosen times to the existing visits if they exist.
    if (!isLoadingPatchFlowVisits && patchFlowVisits) {
      resetChosenTimes();
    }
  }, [isLoadingPatchFlowVisits, patchFlowVisits]);

  const {
    placement: placementTime,
    readingOne: readingOneTime,
    readingTwo: readingTwoTime,
  } = chosenTimes;

  const createPatchFlowVisitsMutation = useMutation(createPatchFlowVisits, {
    onSuccess(patchFlowVisits, {patchFlowId}) {
      const queryKey = getPatchFlowVisitsByPatchFlowIdQueryKey(patchFlowId);
      queryClient.setQueryData(queryKey, patchFlowVisits);
    },
  });

  const deletePatchFlowVisitsMutation = useMutation(deletePatchFlowVisits, {
    onSuccess(_, patchFlowId) {
      const queryKey = getPatchFlowVisitsByPatchFlowIdQueryKey(patchFlowId);
      queryClient.setQueryData(queryKey, []);
    },
  });

  const updateChosenTimes = (update: Partial<ChosenTimes>) => {
    const newChosenTimes = {
      ...chosenTimes,
      ...update,
    };
    // Get next visit type to select
    const nextVisitTypeToSelect = getNextVisitTypeToSelect(newChosenTimes);
    setChosenTimes(newChosenTimes);
    if (nextVisitTypeToSelect !== visitTypeToSelect) {
      setVisitTypeToSelect(nextVisitTypeToSelect);
    }
  };

  const setPlacementTime = (startTime: Date | null) => {
    // Don't nullify times for reading one and reading two if they are compatible with the new
    // placement start time.
    const isReadingOneTimeCompatible = isCompatibleFollowUpTime(startTime, readingOneTime);
    const nextReadingOneTime = isReadingOneTimeCompatible ? readingOneTime : null;
    const nextReadingTwoTime = isReadingOneTimeCompatible ? readingTwoTime : null;
    updateChosenTimes({
      placement: startTime,
      readingOne: nextReadingOneTime,
      readingTwo: nextReadingTwoTime,
    });
  };
  const setReadingOneTime = (startTime: Date | null) => {
    // Don't nullify time for reading two if it is compatible with the new reading one start time.
    const isReadingTwoTimeCompatible = isCompatibleFollowUpTime(startTime, readingTwoTime);
    const nextReadingTwoTime = isReadingTwoTimeCompatible ? readingTwoTime : null;
    updateChosenTimes({
      readingOne: startTime,
      readingTwo: nextReadingTwoTime,
    });
  };
  const setReadingTwoTime = (startTime: Date | null) => {
    updateChosenTimes({
      readingTwo: startTime,
    });
  };
  const clearPlacementTime = () => {
    setPlacementTime(null);
  };
  const clearReadingOneTime = () => {
    setReadingOneTime(null);
  };
  const clearReadingTwoTime = () => {
    setReadingTwoTime(null);
  };


  const {
    data: shippingAddress,
    isLoading: isLoadingShippingAddress,
  } = useQuery(getPatientShippingAddressQueryKey(patientId), async () => {
    return get<PostalAddress>(`/patients/${patientId}/shipping-address`);
  });

  const availabilityQueryKey = getPatchFlowVisitAvailabilityQueryKey({
    state: shippingAddress?.region,
    after: startTime,
    before: endTime,
  });
  const {
    data: providerAvailabilities,
    isLoading: isLoadingAvailabilities,
  } = useQuery(availabilityQueryKey, async () => {
    return await fetchAvailability({
      state: (shippingAddress as PostalAddress).region,
      after: startTime,
      before: endTime,
    })
  }, {
    enabled: Boolean(shippingAddress),
  });

  const isLoading = isLoadingAvailabilities || isLoadingPatchFlowVisits || isLoadingShippingAddress;
  if (isLoading || !providerAvailabilities || !patchFlowVisits || !shippingAddress) {
    return <Loading />;
  }

  const providerIds = providerAvailabilities.map((av) => av.provider_id);
  patchFlowVisits.forEach((visit) => {
    if (!providerIds.some((providerId) => providerId === visit.provider_id)) {
      providerIds.push(visit.provider_id);
    }
  });
  const providers = providerIds.map((providerId) => ({id: providerId}));

  const providerStartTimes = providerAvailabilities.map((av, idx) => {
    const provider = providers[idx];
    const providerPatchFlowVisitStartTimes = patchFlowVisits.filter((v) => v.provider_id === provider.id)
      .map((v) => new Date(v.start_time));
    const startTimes = getAllVisitStartTimes(av.slots).concat(providerPatchFlowVisitStartTimes);
    return {
      provider,
      startTimes,
    };
  });
  const slots: AvailableSlot[] = providerStartTimes.map(({provider, startTimes}) => {
    return startTimes.map((startTime) => {
      return {provider, startTime};
    });
  }).flat();
  // ascending by startTime.
  slots.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

  const availableSlots = new AvailableSlots(slots);

  const placementTimes = availableSlots.getPossiblePlacementStartTimes();
  const readingOneTimes = placementTime ? availableSlots.getPossibleReadingOneStartTimes(placementTime) : null;
  const readingTwoTimes = readingOneTime ? availableSlots.getPossibleReadingTwoStartTimes(readingOneTime) : null;

  // Can only submit if (1) startTime is not null AND (2) startTime is not the same as the existing start time
  // (i.e. it hasn't been changed)
  const isStartTimeDirty = (startTime: Date | null, existingStartTime: Date | null) => {
    if (!startTime) {
      return false;
    }
    if (!existingStartTime) {
      return true;
    }
    return startTime.getTime() !== existingStartTime.getTime();
  };

  const isPlacementStartTimeDirty = isStartTimeDirty(placementTime, existingPlacementStartTime);
  const isReadingOneStartTimeDirty = isStartTimeDirty(readingOneTime, existingReadingOneStartTime);
  const isReadingTwoStartTimeDirty = isStartTimeDirty(readingTwoTime, existingReadingTwoStartTime);
  const areAllStartTimesSet = Boolean(placementTime && readingOneTime && readingTwoTime);
  const isAnyStartTimeDirty = isPlacementStartTimeDirty || isReadingOneStartTimeDirty || isReadingTwoStartTimeDirty;
  const canSubmit = areAllStartTimesSet && isAnyStartTimeDirty;
  const isSubmitting = createPatchFlowVisitsMutation.isLoading;

  const canDelete = existingPlacementStartTime && existingPlacementStartTime.getTime() >= startTime.getTime();
  const isDeleting = deletePatchFlowVisitsMutation.isLoading;

  const onSubmit = () => {
    if (!patchFlowId) {
      return;
    }
    if (!placementTime || !readingOneTime || !readingTwoTime) {
      return;
    }
    const providerIds = availableSlots.getProviderIdsForStartTimes([
      placementTime,
      readingOneTime,
      readingTwoTime,
    ]);
    if (providerIds.length === 0) {
      // NOTE: This cannot happen due to how we allowed for start times to be chosen.
      return;
    }
    // TODO: Is there a better way to choose a provider if there are multiple available for
    // the chosen times?
    // Will choosing the first provider lead to saturation of one provider and not the other?
    // Is random better? i.e. providerIdx = Math.floor(Math.random() * providerIds.length)
    const providerId = providerIds[0];
    const startTimes = [placementTime, readingOneTime, readingTwoTime];
    createPatchFlowVisitsMutation.mutate({
      patchFlowId,
      providerId,
      startTimes,
    });
  };

  const onDelete = () => {
    if (!patchFlowId) {
      return;
    }
    deletePatchFlowVisitsMutation.mutate(patchFlowId);
    setShowDelete(false);
  }

  const getSlotsAccordionArgs = () => {
    switch (visitTypeToSelect) {
      case PatchFlowVisitTypeEnum.Placement:
        return { chosenTime: placementTime, availableTimes: placementTimes, setChosenTime: setPlacementTime };
      case PatchFlowVisitTypeEnum.ReadingOne:
        return { chosenTime: readingOneTime, availableTimes: readingOneTimes, setChosenTime: setReadingOneTime };
      case PatchFlowVisitTypeEnum.ReadingTwo:
        return { chosenTime: readingTwoTime, availableTimes: readingTwoTimes, setChosenTime: setReadingTwoTime };
      default:
        throw new Error('Impossible!');
    }
  };
  const accordionArgs = getSlotsAccordionArgs();

  const getChooseTimeHeader = () => {
    switch (visitTypeToSelect) {
      case PatchFlowVisitTypeEnum.Placement:
        return 'Choose Time for Patch Placement';
      case PatchFlowVisitTypeEnum.ReadingOne:
        return 'Choose Time for Reading #1';
      case PatchFlowVisitTypeEnum.ReadingTwo:
        return 'Choose Time for Reading #2';
      default:
        throw new Error('Impossible!');
    }
  };

  if (placementTimes.length === 0) {
    // TODO: What to do if there are no available times!?
    return (
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <h1 className="mb-3">Uh-oh!</h1>
        <p>There are no available times in the next month.</p>
        <p>Please contact us or check back soon.</p>
      </div>
    );
  }

  return (
    <div>
      <Row className="mt-3 mb-3 justify-content-center">
        <Col sm="12" md="6" lg="6" xl="4">
          <Link to="/">back</Link>
        </Col>
        <Col sm="12" md="6" lg="6" xl="6">
        </Col>
      </Row>
      <Row className="mb-5 justify-content-center">
        <Col sm="12" md="6" lg="6" xl="4">
          <Card className="mb-5">
            <Card.Header as="h5">Selected Times</Card.Header>
            <Card.Body>
              {patchFlowVisits.length > 0 &&
                <Alert>
                  <p>Your current appointment times are:</p>
                  <ul>
                    {patchFlowVisits.map((v) => {
                      const displayString = getVisitTimeDisplayString(new Date(v.start_time));
                      return (
                        <li key={v.id}>{ displayString }</li>
                      );
                    })}
                  </ul>
                  {canDelete && (
                    <div>
                      <p>If you would like to remove them and reschedule later, click below:</p>
                      <Button className="w-100" variant="danger" disabled={ isDeleting } onClick={() => setShowDelete(true)}>
                        { isDeleting ? 'Removing...' : 'Remove' }
                      </Button>
                      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
                        <Modal.Header closeButton>
                          <Modal.Title>Are you sure?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>Are you sure you want to remove your scheduled appointments?</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" disabled={ isDeleting } onClick={() => setShowDelete(false)}>No</Button>
                          <Button variant="danger" disabled={ isDeleting } onClick={() => onDelete()}>Yes</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  )}
                </Alert>
              }
              <ChosenTimeDisplay
                chosenTime={ placementTime }
                clearTime={ clearPlacementTime }
                title="Patch Placement:"
                active={ visitTypeToSelect === PatchFlowVisitTypeEnum.Placement }
                onClick={ () => setVisitTypeToSelect(PatchFlowVisitTypeEnum.Placement) }
              />
              <ChosenTimeDisplay
                chosenTime={ readingOneTime }
                clearTime={ clearReadingOneTime }
                title="Reading One:"
                active={ visitTypeToSelect === PatchFlowVisitTypeEnum.ReadingOne }
                disabled={ !placementTime }
                onClick={ () => setVisitTypeToSelect(PatchFlowVisitTypeEnum.ReadingOne) }
              />
              <ChosenTimeDisplay
                chosenTime={ readingTwoTime }
                clearTime={ clearReadingTwoTime }
                title="Reading Two:"
                active={ visitTypeToSelect === PatchFlowVisitTypeEnum.ReadingTwo && !readingTwoTime }
                disabled={ !readingOneTime }
                onClick={ () => setVisitTypeToSelect(PatchFlowVisitTypeEnum.ReadingTwo) }
              />
              {isSubmitting ?
                <Button className="w-100" disabled>Submitting...</Button>
                :
                <Button className="w-100" onClick={ onSubmit } disabled={ !canSubmit }>Submit</Button>
              }
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" md="6" lg="6" xl="6">
          <Card className="mb-5">
            <Card.Header as="h5">{ getChooseTimeHeader() }</Card.Header>
            <Card.Body>
              <SlotsAccordion
                chosenTime={ accordionArgs.chosenTime }
                availableTimes={ accordionArgs.availableTimes }
                setChosenTime={ accordionArgs.setChosenTime }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PatchFlowSchedulingRoute;