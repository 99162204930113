type PatchAllergen =
  'nickel-sulfate' |
  'mi-and-mci-mix' |
  'fragrance-mix-one' |
  'fragrance-mix-two' |
  'formaldehyde' |
  'balsam-of-peru' |
  '4-phenylenediamine-base' |
  'propylene-glycol' |
  'lanolin-alcohol' |
  'quaternium-15' |
  '2-hydroxyethyl methacrylate' |
  'methyldibromo-glutaronitrile-phenoxyethanol' |
  'cocamidopropyl-betaine' |
  'paraben-mix' |
  'iodopropynyl-butylcarbamate' |
  'negative-control';

const PATCH_ALLERGENS: PatchAllergen[] = [
  'nickel-sulfate',
  'mi-and-mci-mix',
  'fragrance-mix-one',
  'fragrance-mix-two',
  'formaldehyde',
  'balsam-of-peru',
  '4-phenylenediamine-base',
  'propylene-glycol',
  'lanolin-alcohol',
  'quaternium-15',
  '2-hydroxyethyl methacrylate',
  'methyldibromo-glutaronitrile-phenoxyethanol',
  'cocamidopropyl-betaine',
  'paraben-mix',
  'iodopropynyl-butylcarbamate',
  'negative-control',
];

const getPatchAllergenName = (allergen: PatchAllergen): string => {
  switch (allergen) {
    case 'nickel-sulfate':
      return 'Nickel Sulfate';
    case 'mi-and-mci-mix':
      return 'Methylisothiazolinone [MI] and Methylchloroisothiazolinone [MCI] mix';
    case 'fragrance-mix-one':
      return 'Fragrance Mix I';
    case 'fragrance-mix-two':
      return 'Fragrance Mix II';
    case 'formaldehyde':
      return 'Formaldehyde';
    case 'balsam-of-peru':
      return 'Balsam of Peru [myroxylon pereirae resin]';
    case '4-phenylenediamine-base':
      return '4-Phenylenediamine Base';
    case 'propylene-glycol':
      return 'Propylene Glycol';
    case 'lanolin-alcohol':
      return 'Lanolin Alcohol';
    case 'quaternium-15':
      return 'Quaternium-15';
    case '2-hydroxyethyl methacrylate':
      return '2-Hydroxyethyl methacrylate [HEMA]';
    case 'methyldibromo-glutaronitrile-phenoxyethanol':
      return 'Methyldibromo Glutaronitrile/Phenoxyethanol';
    case 'cocamidopropyl-betaine':
      return 'Cocamidopropyl betaine';
    case 'paraben-mix':
      return 'Paraben Mix';
    case 'iodopropynyl-butylcarbamate':
      return 'Iodopropynyl Butylcarbamate';
    case 'negative-control':
      return 'Negative Control';
  }
};

const getPatchAllergenWellNumber = (allergen: PatchAllergen): number => {
  switch (allergen) {
    case 'nickel-sulfate':
      return 1;
    case 'mi-and-mci-mix':
      return 2;
    case 'fragrance-mix-one':
      return 3;
    case 'fragrance-mix-two':
      return 4;
    case 'formaldehyde':
      return 5;
    case 'balsam-of-peru':
      return 6;
    case '4-phenylenediamine-base':
      return 7;
    case 'propylene-glycol':
      return 8;
    case 'lanolin-alcohol':
      return 9;
    case 'quaternium-15':
      return 10;
    case '2-hydroxyethyl methacrylate':
      return 11;
    case 'methyldibromo-glutaronitrile-phenoxyethanol':
      return 12;
    case 'cocamidopropyl-betaine':
      return 13;
    case 'paraben-mix':
      return 14;
    case 'iodopropynyl-butylcarbamate':
      return 15;
    case 'negative-control':
      return 16;
  }
};

export default PatchAllergen;
export {
  PATCH_ALLERGENS,
  getPatchAllergenName,
  getPatchAllergenWellNumber,
};