type SetMap = {
  [key: string]: boolean,
}

const scriptNotLoadedMap: SetMap = {};

// Following ideas from:
// * https://rangen.medium.com/dynamically-load-google-scripts-with-react-and-the-useeffect-hook-3700b908e50f
// * https://betterprogramming.pub/loading-third-party-scripts-dynamically-in-reactjs-458c41a7013d
// * https://github.com/dropbox/zxcvbn#script-load-latency
// * https://friendlybit.com/js/lazy-loading-asyncronous-javascript/
const loadScript = async (scriptId: string, scriptSrc: string): Promise<void> => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      // Script exists, but check if it has been loaded!
      if (scriptNotLoadedMap[scriptId]) {
        existingScript.addEventListener('load', () => {
          resolve();
        });
      } else {
        resolve();
      }
      return;
    }
    scriptNotLoadedMap[scriptId] = true;
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = scriptSrc;
    script.type = "text/javascript";
    // Probably not necessary...
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      delete scriptNotLoadedMap[scriptId];
      resolve();
    });
  });
};

export default loadScript;