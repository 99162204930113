enum QuestionnaireCompletionState {
  Pregnant = 'pregnant',
  TopicalCream = 'topical-cream',
  Steroids = 'steroids',
  UvTreatment = 'uv-treatment',
  ArmRash = 'arm-rash',
  ArmMarkings = 'arm-markings',
  Medications = 'medications',
  Schedule = 'schedule',
  Review = 'review',
};

// Ideally this reverse mapping is determined programmatically, but
// I wasn't able to figure that out in a reasonable amount of time,
// so here we go.
const reverseMapping = {
  'pregnant': QuestionnaireCompletionState.Pregnant,
  'topical-cream': QuestionnaireCompletionState.TopicalCream,
  'steroids': QuestionnaireCompletionState.Steroids,
  'uv-treatment': QuestionnaireCompletionState.UvTreatment,
  'arm-rash': QuestionnaireCompletionState.ArmRash,
  'arm-markings': QuestionnaireCompletionState.ArmMarkings,
  'medications': QuestionnaireCompletionState.Medications,
  'schedule': QuestionnaireCompletionState.Schedule,
  'review': QuestionnaireCompletionState.Review,
} as {
  [key: string]: QuestionnaireCompletionState,
};
function questionnaireCompletionStateFromQueryString(query: string | null): QuestionnaireCompletionState | null {
  if (query === null) {
    return null;
  }
  return reverseMapping[query] || null;
}

function getLinkForQuestionnaireCompletionState(questionnaireId: string, state: QuestionnaireCompletionState): string {
  return `/questionnaires/${questionnaireId}?q=${state}`;
}

export default QuestionnaireCompletionState;

export {
  getLinkForQuestionnaireCompletionState,
  questionnaireCompletionStateFromQueryString,
}