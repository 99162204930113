import { FormEventHandler, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Navigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { get, post } from 'webapp-common/api/RestApi';
import PatchOrder from 'webapp-common/models/PatchOrder';

import Loading from '../components/Loading';
import { getPatchOrderByIdQueryKey } from '../utils/query-keys';

interface PostActivatePatchParams {
  patchOrderId: string,
  activationCode: string,
}
const postActivatePatch = async ({patchOrderId, activationCode}: PostActivatePatchParams) => {
  return post<PatchOrder>(`/patch-orders/${patchOrderId}/activate`, {
    activation_code: activationCode,
  });
};

function PatchOrderRoute() {
  const { patchOrderId } = useParams();
  const queryClient = useQueryClient();

  const [activationCode, setActivationCode] = useState('');

  const patchOrderByIdQueryKey = getPatchOrderByIdQueryKey(patchOrderId);
  const {
    data: patchOrder,
    isLoading, 
  } = useQuery(patchOrderByIdQueryKey, async () => {
    if (!patchOrderId) {
      // TODO: could also check if patchOrderId is a UUID
      throw new Error('Missing patch order id');
    }
    return await get<PatchOrder>(`/patch-orders/${patchOrderId}`)
  });

  const activatePatchMutation = useMutation(postActivatePatch, {
    onSuccess(patchOrder) {
      const queryKey = getPatchOrderByIdQueryKey(patchOrder.id);
      queryClient.setQueryData(queryKey, patchOrder);
    },
  });

  const activatePatch: FormEventHandler = async (event) => {
    event.preventDefault();
    if (!patchOrderId) {
      throw new Error('Missing patch order ID');
    }
    activatePatchMutation.mutate({patchOrderId, activationCode});
  }

  const isActivatingPatch = activatePatchMutation.isLoading;
  const isActivateDisabled = isActivatingPatch || !activationCode;
  const isPatchActivated = activatePatchMutation.isSuccess;

  if (isLoading) {
    return <Loading />;
  }

  if (!patchOrder) {
    return <Loading />;
  }

  if (isPatchActivated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Row className="justify-content-center mt-5 mb-5">
        <Col sm="6" lg="4">
          <h2>Activate Patch</h2>
          <p>Activate your patch by providing your activation code:</p>
          <Form className="mb-3" onSubmit={activatePatch}>
            <Form.Group className="mb-3" controlId="activationCode">
              <Form.Control type="text" placeholder="Activation Code" onChange={(e) => setActivationCode(e.target.value)} />
            </Form.Group>
            <Button disabled={isActivateDisabled} type="submit" className="w-100">
              {isActivatingPatch ? 'Activating...' : 'Activate'}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )

}

export default PatchOrderRoute;