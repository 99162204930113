import QuestionnaireReviewStatus from './QuestionnaireReviewStatus';

export default interface Questionnaire {
  id: string,
  patch_flow_id: string,

  is_pregnant_or_breastfeeding: boolean | null,
  has_used_topical_creams: boolean | null,
  has_used_steroids: boolean | null,
  has_done_uv_treatment: boolean | null,
  has_active_arm_rash: boolean | null,
  has_arm_markings: boolean | null,
  has_completed_medications: boolean | null,
  can_commit_to_schedule: boolean | null,

  submitted_at: Date | null,

  reviewer_id: string | null,
  reviewed_at: Date | null,
  review_status: QuestionnaireReviewStatus | null,
  review_note: string | null,
}

const canSubmitQuestionnaire = (q: Questionnaire) => {
  if (isQuestionnaireSubmitted(q)) {
    return false;
  }
  return (
    q.is_pregnant_or_breastfeeding !== null &&
    q.has_used_topical_creams !== null &&
    q.has_used_steroids !== null &&
    q.has_done_uv_treatment !== null &&
    q.has_active_arm_rash !== null &&
    q.has_arm_markings !== null &&
    q.has_completed_medications &&
    q.can_commit_to_schedule !== null
  );
};

const isQuestionnaireSubmitted = (q: Questionnaire) => {
  return q.submitted_at !== null;
};

export {
  canSubmitQuestionnaire,
  isQuestionnaireSubmitted,
};