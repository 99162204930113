import Alert from 'react-bootstrap/Alert';

function MedicalRequirementsBody() {
  return (
    <div>
      <Alert variant="danger">
        TODO: UPDATE COPY
      </Alert>
      <p>Please review and agree to the following medical requirements:</p>
      <ul>
        <li>I am not pregnant or breastfeeding.</li>
        <li>I have not used any steroid creams recently.</li>
        <li>I have not had any UV treatment recently.</li>
        <li>I do not have an active rash on both of my upper arms.</li>
        <li>I do not have any tattoos or permanent markings on both of my upper arms.</li>
        <li>I am not taking one of the medications on <a href="#">this list</a>.</li>
      </ul>
    </div>
  )
}

export default MedicalRequirementsBody;