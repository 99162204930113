import Patient, { isPatientFullyRegistered } from 'webapp-common/models/Patient';
import User from 'webapp-common/models/User';


type RedirectObject = {
  pathname: string,
  includeFrom?: boolean,
  replace?: boolean,
};


const PUBLIC_ONLY_PATHNAMES = [
  '/login',
  '/register',
];


const getRedirect = ({
  pathname,
  authUser,
  patient,
}: {
  pathname: string,
  authUser: User | null,
  patient?: Patient,
}): RedirectObject | null => {
  // Unauthenticated must log in for private paths (or register).
  if (!authUser) {
    if (PUBLIC_ONLY_PATHNAMES.includes(pathname)) {
      return null;
    }
    return {
      pathname: '/login',
      includeFrom: true,
      replace: true,
    };
  }
  // Authenticated scenarios (`authUser` exists).

  // If authenticated, it does not make sense to go to the public only paths.
  if (PUBLIC_ONLY_PATHNAMES.includes(pathname)) {
    return {
      pathname: '/',
      replace: true,
    };
  }
  // If authenticated and email not verified, make sure they go to `/verify-email`.
  if (!authUser.isEmailVerified) {
    if (pathname === '/verify-email') {
      return null;
    }
    return {
      pathname: '/verify-email',
      includeFrom: true,
      replace: true,
    };
  }

  // If authenticated but hasn't completed registration, go there!
  if (patient && !isPatientFullyRegistered(patient) && !pathname.startsWith('/registration')) {
    return {
      pathname: '/registration',
    }
  }
  return null;

};

export {
  getRedirect,
}