import { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.scss';
import Container from 'react-bootstrap/Container';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { useAuth } from 'webapp-common/auth/AuthProvider';
import { get } from 'webapp-common/api/RestApi';
import Patient from 'webapp-common/models/Patient';

import Loading from './components/Loading';
import Navbar from './components/Navbar';
import { getRedirect } from './utils/redirect';

import HomeRoute from './routes/HomeRoute';
import LoginRoute from './routes/LoginRoute';
import RegisterRoute from './routes/RegisterRoute';
import RegistrationRootRoute from './routes/registration/RegistrationRootRoute';
import AdditionalInformationRoute from './routes/registration/AdditionalInformationRoute';
import HipaaConsentRoute from './routes/registration/HipaaConsentRoute';
import TreatmentConsentRoute from './routes/registration/TreatmentConsentRoute';
import TechnicalRequirementsRoute from './routes/registration/TechnicalRequirementsRoute';
import LogisticalRequirementsRoute from './routes/registration/LogisticalRequirementsRoute';
import MedicalRequirementsRoute from './routes/registration/MedicalRequirementsRoute';
import QuestionnairesRootRoute from './routes/questionnaire/QuestionnairesRootRoute';
import QuestionnaireRoute from './routes/questionnaire/QuestionnaireRoute';
import SettingsRoute from './routes/SettingsRoute';
import VerifyEmailRoute from './routes/VerifyEmailRoute';
import PatchFlowCreatePatchOrderRoute from './routes/patch-flow/PatchFlowCreatePatchOrderRoute';
import PatchFlowSchedulingRoute from './routes/patch-flow/PatchFlowSchedulingRoute';
import PatchOrderRoute from './routes/PatchOrderRoute';
import { getPatientByIdQueryKey } from './utils/query-keys';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = location;
  const auth = useAuth();
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState<boolean>(true);

  const patientId = auth.user?.id;
  const {
    data: patient,
  } = useQuery(getPatientByIdQueryKey(patientId), async () => {
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    return await get<Patient>(`/patients/${patientId}`);
  }, {
    enabled: Boolean(patientId),
  });

  const loadAuthUser = async () => {
    await auth.load();
    setIsLoadingAuthUser(false);
  };
  useEffect(() => {
    loadAuthUser();
  }, []);
  
  if (isLoadingAuthUser) {
    return <Loading />;
  }

  // If we have the auth user but not yet the patient,
  // keep loading until we get the patient.
  if (auth.user && !patient) {
    return <Loading />;
  }

  const getRedirectIfNecessary = () => {
    if (auth.user && !patient) {
      // We shouldn't get here anyway due to the check above...
      return null;
    }
    return getRedirect({
      pathname,
      authUser: auth.user,
      patient,
    });
  };

  const redirect = getRedirectIfNecessary();
  if (redirect) {
    const shouldReplace = redirect.replace || false;
    if (redirect.includeFrom) {
      return <Navigate to={ redirect.pathname } state={{from: location}} replace={shouldReplace} />;
    } else {
      return <Navigate to={ redirect.pathname } replace={shouldReplace} />;
    }
  }

  const signOut = async () => {
    await auth.signout();
    queryClient.clear();
    navigate("/login");
  }

  return (
    <div>
      <Navbar isAuthenticated={auth.user !== null} signOut={signOut}/>
      <Container fluid>
        <Routes>
          <Route path="/" element={<HomeRoute />} />
          <Route path="/settings" element={<SettingsRoute />} />
          <Route path="/login" element={<LoginRoute />} />
          <Route path="/register" element={<RegisterRoute />} />
          <Route path="/verify-email" element={<VerifyEmailRoute />} />
          <Route path="/registration" element={<RegistrationRootRoute />}>
            <Route path="additional-information" element={<AdditionalInformationRoute />}></Route>
            <Route path="hipaa-consent" element={<HipaaConsentRoute />}></Route>
            <Route path="treatment-consent" element={<TreatmentConsentRoute />}></Route>
            <Route path="technical-requirements" element={<TechnicalRequirementsRoute />}></Route>
            <Route path="logistical-requirements" element={<LogisticalRequirementsRoute />}></Route>
            <Route path="medical-requirements" element={<MedicalRequirementsRoute />}></Route>
          </Route>
          <Route path="/questionnaires" element={<QuestionnairesRootRoute />}>
            <Route path=":questionnaireId" element={<QuestionnaireRoute />} />
          </Route>
          <Route path="/patch-flows/:patchFlowId/patch-order" element={<PatchFlowCreatePatchOrderRoute />}></Route>
          <Route path="/patch-orders/:patchOrderId" element={<PatchOrderRoute />}></Route>
          <Route path="/patch-flows/:patchFlowId/scheduling" element={<PatchFlowSchedulingRoute />}></Route>
        </Routes>
      </Container>
    </div>
  );
}

export default App;
