import ProgressBar from "react-bootstrap/ProgressBar";

function PasswordStrengthMeter({className, score}: {className: string, score: number}) {
  // score is -1 (none), 0, 1, 2, 3, 4
  const scorePlusOne = score + 1;
  const percentage = scorePlusOne * 20;
  const labels = [
    '',
    'Very Weak',
    'Weak',
    'Moderate',
    'Strong',
    'Very Strong',
  ]
  const variants = [
    "danger",
    "danger",
    "danger",
    "warning",
    "success",
    "success",
  ]
  const label = labels[scorePlusOne];
  const variant = variants[scorePlusOne];

  return (
    <ProgressBar className={className} now={percentage} variant={variant} label={label} visuallyHidden style={{height: '5px'}}/>
  )
}

export default PasswordStrengthMeter;