import Questionnaire from './Questionnaire';
import QuestionnaireReviewStatus from './QuestionnaireReviewStatus';

enum QuestionnaireState {
  InProgress,
  Submitted,
  Approved,
  Denied,
}

const getQuestionnaireState = (questionnaire: Questionnaire): QuestionnaireState => {
  if (questionnaire.submitted_at === null) {
    return QuestionnaireState.InProgress;
  } else if (questionnaire.reviewed_at === null) {
    return QuestionnaireState.Submitted;
  } else if (questionnaire.review_status === QuestionnaireReviewStatus.Approved) {
    return QuestionnaireState.Approved;
  } else if (questionnaire.review_status === QuestionnaireReviewStatus.Denied) {
    return QuestionnaireState.Denied;
  }
  // This should be unreachable...
  return QuestionnaireState.Denied;
};

export default QuestionnaireState;

export {
  getQuestionnaireState,
};