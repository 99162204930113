import Accordion from 'react-bootstrap/Accordion'
import { FaCheck, FaRegCircle } from 'react-icons/fa';

import PatchFlowStepState from '../types/PatchFlowStepState';

function PatchFlowStep({
  eventKey,
  state,
  header,
  content,
}: {
  eventKey: string,
  state: PatchFlowStepState,
  header: string,
  content: JSX.Element,
}) {
  const isComplete = state === PatchFlowStepState.Completed;
  const isFuture = state === PatchFlowStepState.Future;
  const headerIcon = isComplete ? <FaCheck color="green" className="me-2" /> : <FaRegCircle className="me-2" />;
  return (
    <Accordion.Item eventKey={ eventKey }>
      <h2 className="accordion-header">
        <Accordion.Button className={isFuture ? "text-muted" : ""}>
          { headerIcon }
          { header }
        </Accordion.Button>
      </h2>
      <Accordion.Body>
        { content }
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default PatchFlowStep;