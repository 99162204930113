import { Navigate } from 'react-router-dom';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import RegistrationConsentContainer from '../../components/registration/RegistrationConsentContainer';
import MedicalRequirementsBody from '../../components/registration/MedicalRequirementsBody';
import useUpdateConsentMutation from '../../utils/useUpdateConsentMutation';


function MedicalRequirementsRoute() {
  const auth = useAuth();
  const updateConsentMutation = useUpdateConsentMutation();

  const submit = () => {
    const patientId = auth.user?.id;
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    updateConsentMutation.mutate({patientId, consentPath: 'medical-requirements'});
  }

  if (updateConsentMutation.isSuccess) {
    return <Navigate to="/registration" />;
  }

  return (
    <RegistrationConsentContainer
      title={ 'Medical Requirements' }
      canAgree={ true }
      isSubmitting={ updateConsentMutation.isLoading }
      submit={ submit }>
      <MedicalRequirementsBody />
    </RegistrationConsentContainer>
  );
}

export default MedicalRequirementsRoute;