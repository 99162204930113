import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

import Questionnaire from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';

import QuestionnaireCompletionState, {
  getLinkForQuestionnaireCompletionState,
} from '../../types/QuestionnaireCompletionState';

function QuestionnaireResponsesTable({
  questionnaire,
  medications,
  showEditLink,
}: {
  questionnaire: Questionnaire,
  medications: QuestionnaireMedication[],
  showEditLink: boolean,
}) {
  const getLink = (state: QuestionnaireCompletionState): string => {
    return getLinkForQuestionnaireCompletionState(questionnaire.id, state);
  }

  const booleanToYesNo = (val: boolean | null): string => {
    if (val === true) {
      return 'Yes';
    } else if (val === false) {
      return 'No';
    } else {
      return '-';
    }
  };

  const getMedicationsList = () => {
    if (medications.length === 0) {
      return (
        <li><em>None</em></li>
      );
    }
    return medications.map((med) => (
      <li key={med.id}>{med.name}</li>
    ));
  };


  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th></th>
          {showEditLink && <th></th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Are you pregnant or breastfeeding?</td>
          <td>{booleanToYesNo(questionnaire.is_pregnant_or_breastfeeding)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.Pregnant)}>edit</Link></td>}
        </tr>
        <tr>
          <td>Have you put any steroid creams or other immunosuppressant topical creams [Elidel (pimecrolimus), Protopic (tacrolimus), Eucrisa (crisaborole)] on your upper arm skin during the last 7 days?</td>
          <td>{booleanToYesNo(questionnaire.has_used_topical_creams)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.TopicalCream)}>edit</Link></td>}
        </tr>
        <tr>
          <td>Have you taken any oral or injectable treatment with steroids in the last 30 days? Have you taken any other medications that suppress your immune system in the last 30 days?</td>
          <td>{booleanToYesNo(questionnaire.has_used_steroids)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.Steroids)}>edit</Link></td>}
        </tr>
        <tr>
          <td>Have you had any treatment with ultraviolet (UV) light (including tanning) during the previous 3 weeks?</td>
          <td>{booleanToYesNo(questionnaire.has_done_uv_treatment)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.UvTreatment)}>edit</Link></td>}
        </tr>
        <tr>
          <td>Do you have any active rash on both of your upper arms (between your elbow and your shoulder) where the patch will be placed?</td>
          <td>{booleanToYesNo(questionnaire.has_active_arm_rash)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.ArmRash)}>edit</Link></td>}
        </tr>
        <tr>
          <td>Do you have any tattoos or permanent markings on BOTH of your upper arms which may affect being able to read the patch?</td>
          <td>{booleanToYesNo(questionnaire.has_arm_markings)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.ArmMarkings)}>edit</Link></td>}
        </tr>
        <tr>
          <td>
            <p className="mb-0">Medications:</p>
            <ul className="mb-0">
              {getMedicationsList()}
            </ul>
          </td>
          {/* Maybe make this a number and a link to the item */}
          <td>{medications.length}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.Medications)}>edit</Link></td>}
        </tr>
        <tr>
          <td>The timing is strict in order to produce accurate test results. Once appointments are made, they cannot be rescheduled and the test is non-refundable. Are you able to commit to the 3 virtual video appointments [lasting 5 to 10 minutes] scheduled by you?</td>
          <td>{booleanToYesNo(questionnaire.can_commit_to_schedule)}</td>
          {showEditLink && <td><Link to={getLink(QuestionnaireCompletionState.Schedule)}>edit</Link></td>}
        </tr>
      </tbody>
    </Table>
  )
}

export default QuestionnaireResponsesTable;